var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"about\">\n	<div class=\"about__video\">\n		<div class=\"about__video-loading\">\n			"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.loading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":4,"column":27}}}))
    + "\n		</div>\n		<video width=\"444\" height=\"388\" autoplay muted loop playsinline pip=\"false\">\n			<source src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":29}}}) : helper)))
    + "/images/station.webm\" type=\"video/webm; codecs=&quot;vp9&quot;\">\n			<source src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":29}}}) : helper)))
    + "/images/station.mp4\" type=\"video/mp4\">\n		</video>\n	</div>\n	<div class=\"about__body\">\n		"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.main",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":12,"column":25}}})) != null ? stack1 : "")
    + "\n		<div class=\"about__buttons\">\n			<!--<button class=\"button button_border\" data-role=\"timelapse\">"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.timelapse",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":66},"end":{"line":14,"column":92}}}))
    + "</button>-->\n			<button class=\"button button_border\" data-role=\"download-as-image\">"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.download-as-image",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":70},"end":{"line":15,"column":104}}}))
    + "</button>\n		</div>\n	</div>\n</div>\n";
},"useData":true});