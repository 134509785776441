import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import Spinner from "ui/components/spinner/Spinner";
import Ajax from "ui/helpers/Ajax";
import FatalError from "ui/components/fatal-error/FatalError";
import Form from "ui/elements/form/Form";
import {validatePassword} from "ui/helpers/validators";

/**
 * @typedef {object} T_ChangePasswordFormData
 * @property {string} password_old
 * @property {string} password_new1
 * @property {string} password_new2
 */

export default class ChangePassword extends Dialog {
	constructor() {
		super({
			title: i18n('title.change-password'),
			smallWidth: true,
			buttons: [
				{
					caption: i18n('buttons.apply'),
					role: 'apply',
					icon: '/images/icons.svg#icon-svg-check'
				},
				{
					caption: i18n('buttons.close'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {Form}
		 * @private
		 */
		this._form = null;

		/**
		 * @type {?function(result:boolean)}
		 * @private
		 */
		this._onComplete = null;

		void this._buildForm();
	}

	/**
	 * @returns {Promise<boolean>}
	 */
	getResultPromise() {
		return new Promise((resolve) => {
			this._onComplete = resolve;
		});
	}

	/**
	 * @override
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		super._handleButton(role);

		if (role === 'apply') {
			let data = this._form.getValues();
			if (data !== null) {
				this._apply(data).then();
			}
		}
	}

	/**
	 * @private
	 * @param {T_ChangePasswordFormData} data
	 * @returns {void}
	 */
	async _apply(data) {
		if (data.password_new1 !== data.password_new2) {
			this._form.showError(i18n('errors.user.passwords-not-match'), ['password_new1', 'password_new2']);
			return;
		}

		if (data.password_new1 === data.password_old) {
			this._form.showError(i18n('errors.user.passwords-equal'), ['password_old', 'password_new1']);
			return;
		}

		Spinner.show();

		let res;
		try {
			res = await Ajax.post('/user/change-password', {
				password_old: data.password_old,
				password_new: data.password_new1,
			});

			if (res.result === 'error') {
				this._form.showError(res.error, res.fields || []);
				Spinner.hide();
				return;
			}
		} catch (e) {
			FatalError.show(typeof e === 'string' ? e : i18n('errors.http.error'));
			Spinner.hide();
			return;
		}

		Spinner.hide();

		if (this._onComplete) {
			this._onComplete(true);
			this._onComplete = null;
			this.destroy();
		}
	}

	/**
	 * @private
	 * @returns {Promise<void>}
	 */
	async _buildForm() {
		const el = document.createElement('div');

		this._form = new Form({
			parent: el,
			modifier: 'form_width-50',
			onApply: this._apply.bind(this),
			values: {},
			fields: [
				{
					type: 'password',
					name: 'password_old',
					fullWidth: true,
					caption: i18n('inputs.signin.password-old'),
					required: true,
					maxlength: 100
				},
				{
					type: 'password',
					name: 'password_new1',
					fullWidth: true,
					caption: i18n('inputs.signin.password-new'),
					validators: [validatePassword],
					required: true,
					maxlength: 100
				},
				{
					type: 'password',
					name: 'password_new2',
					fullWidth: true,
					caption: i18n('inputs.signin.password-new-repeat'),
					validators: [validatePassword],
					required: true,
					maxlength: 100
				},
			]
		});
		this.setContent(el);
	}

	destroy() {
		if (this._onComplete !== null) {
			this._onComplete(false);
			this._onComplete = null;
		}
		super.destroy();
	}
}
