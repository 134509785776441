import PopupMenu from "ui/elements/popup-menu/PopupMenu";
import {i18n} from "ui/i18n";
import ChangePassword from "ui/dialogs/change-password/ChangePassword";
import Ajax from "ui/helpers/Ajax";
import {CurrentUser} from "ui/CurrentUser";

export default class UserMenu extends PopupMenu {
	constructor(target) {
		super({
			target: target,
			buttons: [
				{
					caption: i18n('buttons.change-password'),
					icon: '/images/icons.svg#icon-svg-settings',
					role: 'change-password'
				},
				{
					caption: i18n('buttons.logout'),
					icon: '/images/icons.svg#icon-svg-logout',
					role: 'logout'
				}
			]
		});
	}
	/**
	 * @param {string} role
	 * @returns {Promise<void>}
	 * @protected
	 */
	async _handleButton(role) {
		await super._handleButton(role);
		switch (role) {
			case 'change-password':
				await new ChangePassword().getResultPromise();
				break;
			case 'logout':
				await Ajax.post('/user/logout', {});
				CurrentUser.get().readCookies();
				break;
		}
	}
}
