import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";

export default class ImageElementDialog extends Dialog {
	constructor(title, url) {
		const initWidth = 500;
		super({
			title,
			width: initWidth,
			autoHeight: true,
			content: `
				<div class="image-dialog">
					<div class="image-dialog__loading"><span>${i18n('title.loading')}</span></div>
				</div>
			`
		});

		const img = new Image();
		img.onload = () => {
			const anchor = document.createElement('a');
			anchor.target = '_blank';
			anchor.href = url;
			const cont = this._els.body.querySelector('.image-dialog');
			anchor.appendChild(img);
			cont.appendChild(anchor);
			cont.classList.add('image-dialog_loaded');
		};
		img.src = url;
	}
}
