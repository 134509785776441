import ByteArrayReader from "ui/helpers/blob/ByteArrayReader";
import EventDispatcher from "ui/EventDispatcher";

export default class SocketClient {
	/**
	 * @param {ClientSession} session
	 */
	constructor(session) {
		/**
		 * @type {ClientSession}
		 * @private
		 */
		this._session = session;

		/**
		 * @type {ServiceWorkerContainer|null}
		 * @private
		 */
		this._serviceWorker = null;

		/**
		 * @type {EventDispatcher}
		 * @private
		 */
		this._events = new EventDispatcher();
	}

	/**
	 * @return {EventDispatcher}
	 */
	get events() {
		return this._events;
	}

	async init() {
		if (this._serviceWorker !== null) {
			return;
		}

		const channel = new BroadcastChannel('stat');
		channel.onmessage = (e) => {
			if (e.data.type === 'sw-auth') {
				this._auth();
			} else if (e.data.type === 'sw-message') {
				this._processResponse(e.data.data);
			}
		};

		this._serviceWorker = null;

		await navigator.serviceWorker.register('./sw.js', {updateViaCache: 'none'});
		await navigator.serviceWorker.ready;

		this._serviceWorker = navigator.serviceWorker;

		this._auth();
	}

	/**
	 * @return {boolean}
	 */
	isConnected() {
		return !!(this._serviceWorker !== null && this._serviceWorker.controller);
	}

	/**
	 * @param {Uint8Array} payload
	 */
	sendMessage(payload) {
		if (!this.isConnected()) {
			return;
		}

		this._serviceWorker.controller.postMessage({type: 'message', payload});
	}

	/**
	 * @param {ArrayBuffer} data
	 * @private
	 */
	_processResponse(data) {
		const arr = new Uint8Array(data);
		const commandCode = (new ByteArrayReader(arr)).readUint8();
		this._events.trigger('cmd-' + commandCode, arr.slice(1));
	}

	/**
	 * @private
	 */
	_auth() {
		if (!this.isConnected()) {
			return;
		}

		this._session.update();
		this._serviceWorker.controller.postMessage({
			type: 'auth',
			uuid: this._session.uuid,
			visitNumber: this._session.visitNumber
		});
		this._events.trigger('ready');
	}
}
