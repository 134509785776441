import {i18n} from "ui/i18n";

export const LIMIT_USERNAME_MIN_LENGTH = 4;
export const LIMIT_USERNAME_MAX_LENGTH = 16;

export const LIMIT_SCENE_NAME_MIN_LENGTH = 4;
export const LIMIT_SCENE_NAME_MAX_LENGTH = 64;

export const LIMIT_SCENE_DESC_MAX_LENGTH = 2048;

export const LIMIT_PASSWORD_MIN_LENGTH = 8;

/**
 * @param {string} name
 * @return {string}
 */
export function validateUserName(name) {
	if ((name.length < LIMIT_USERNAME_MIN_LENGTH || name.length > LIMIT_USERNAME_MAX_LENGTH)
		|| !/^[a-z0-9]+$/i.test(name)) {
		return i18n('errors.user.name', LIMIT_USERNAME_MIN_LENGTH, LIMIT_USERNAME_MAX_LENGTH);
	}

	return '';
}

/**
 * @param {string} email
 * @return {string}
 */
export function validateEmail(email) {
	// see: https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
	const flag = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);

	if (!flag) {
		return i18n('errors.user.email');
	}

	return '';
}

/**
 * @param {string} value
 * @return {boolean|string}
 */
export function validateSceneName(value) {
	if (value.length < LIMIT_SCENE_NAME_MIN_LENGTH || value.length > LIMIT_SCENE_NAME_MAX_LENGTH) {
		return i18n('errors.scene.name-length', LIMIT_SCENE_NAME_MIN_LENGTH, LIMIT_SCENE_NAME_MAX_LENGTH);
	}

	return '';
}

/**
 * @param {string} desc
 * @return {string}
 */
export function validateSceneDesc(desc) {
	if (desc.length > LIMIT_SCENE_DESC_MAX_LENGTH) {
		return i18n('errors.scene.desc-length', LIMIT_SCENE_DESC_MAX_LENGTH);
	}

	return '';
}

/**
 * @param {string} value
 * @return {string}
 */
export function validatePassword(value) {
	if (value.length < LIMIT_PASSWORD_MIN_LENGTH) {
		return i18n('errors.user.password-length', LIMIT_PASSWORD_MIN_LENGTH);
	}

	return '';
}
