import {detectUserLanguage, i18n} from "ui/i18n";
import LoginForm from "ui/components/login-form/LoginForm";
import FatalError from "ui/components/fatal-error/FatalError";

/**
 * Ajax Request Controller
 */
export default class Ajax {
	/**
	 * Makes HTTP GET request via Ajax
	 *
	 * @param {string} url
	 * @param {Object} [params]
	 * @returns {Promise<Object>}
	 */
	static get(url, params = null) {
		if (params !== null) {
			let list = [];
			for (let name in params) {
				if (params.hasOwnProperty(name)) {
					list.push(name + '=' + encodeURIComponent(params[name]));
				}
			}
			if (url.indexOf('?') === -1) {
				url += '?';
			}
			url += list.join('&');
		}
		return new Promise((resolve, reject) => {
			Ajax._createXHR(resolve, reject, url);
		});
	}

	/**
	 * Makes HTTP GET request via Ajax
	 *
	 * @param {string} url
	 * @param {Object|FormData} data
	 * @returns {Promise<Object>}
	 */
	static post(url, data) {
		return new Promise((resolve, reject) => {
			data = data || {};
			if (!(data instanceof FormData)) {
				let formData = new FormData();
				for (let key in data) {
					if (data.hasOwnProperty(key)) {
						formData.append(key, data[key]);
					}
				}
				data = formData;
			}
			Ajax._createXHR(resolve, reject, url, data);
		});
	}

	/**
	 * Creates XHR object
	 *
	 * @param {Function} resolve
	 * @param {Function} reject
	 * @param {string} url
	 * @param {Object|FormData} [postData]
	 * @returns {XMLHttpRequest}
	 * @private
	 */
	static _createXHR(resolve, reject, url, postData = null) {
		const send = () => {
			if (postData !== null) {
				request.open('POST', url, true);
				request.setRequestHeader('X-User-Lang', detectUserLanguage());
				request.send(postData);
			} else {
				request.open('GET', url, true);
				request.setRequestHeader('X-User-Lang', detectUserLanguage());
				request.send();
			}
		};
		let request = new XMLHttpRequest();
		request.addEventListener('load', () => {
			if (request.status === 401) {
				new LoginForm().then((res) => {
					if (res) {
						send();
					}
				});
				return;
			}

			if (request.status === 403) {
				reject(i18n('errors.http.forbidden'));
				return;
			}

			if (request.status === 423) {
				FatalError.show(i18n('errors.http.user-locked'), '/');
				return;
			}

			if (request.status === 429) {
				reject(i18n('errors.http.too-many-requests'));
				return;
			}

			let data;
			try {
				data = JSON.parse(request.responseText);
			} catch (e) {
				reject(i18n('errors.http.response'));
				return;
			}
			resolve(data);
		});

		request.addEventListener('error', () => {
			reject(i18n('errors.http.error'));
		});

		request.addEventListener('abort', () => {
			reject(i18n('errors.http.abort'));
		});

		send();
		return request;
	}
}
