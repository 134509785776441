import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import alertView from "./alert.handlebars";
import Template from "ui/Template";

export default class Alert extends Dialog {
	constructor(message, mode = '') {
		super({
			title: i18n('title.message'),
			content: new Template(alertView).createElement({message, mode}),
			showTitle: false,
			smallWidth: true,
			buttons: [
				{
					caption: i18n('buttons.close'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {function(): void}
		 * @private
		 */
		this._resolve = null;
	}

	/**
	 * @return {Promise<void>}
	 */
	async getResult() {
		return new Promise(resolve => {
			this._resolve = resolve;
		});
	}

	/**
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		this._resolve && this._resolve();
		this.destroy();
	}
}
