import FatalError from "ui/components/fatal-error/FatalError";
import Spinner from "ui/components/spinner/Spinner";

/**
 * @private
 * @type {Array<PageElement>}
 */
let focusedElementsStack = [];

/**
 * @typedef {Object} T_PageElementOptions
 * @property {?Element} parent
 */

/**
 * Page Element Base Class
 */
export default class PageElement {
	/**
	 * Class constructor
	 *
	 * @param {T_PageElementOptions} [options]
	 */
	constructor(options = {}) {
		/**
		 * @type {T_PageElementOptions}
		 * @private
		 */
		this._pageElemOptions = options;

		/**
		 * @type {HTMLElement}
		 * @private
		 */
		this._pageElem = null;
	}

	/**
	 * Returns instance of the HTML element
	 *
	 * @protected
	 * @returns {?HTMLElement}
	 */
	get _element() {
		return this._pageElem;
	}

	/**
	 * Sets HTML element of the component
	 *
	 * @protected
	 * @param {?HTMLElement} el
	 */
	set _element(el) {
		if (this._pageElem && this._pageElem.parentNode) {
			this._pageElem.parentNode.removeChild(this._pageElem);
		}
		this._pageElem = el;
	}

	/**
	 * Adds element to DOM and set focus on it
	 *
	 * @returns {void}
	 */
	activatePageElement() {
		if (this._pageElem && this._pageElemOptions.parent) {
			this._pageElemOptions.parent.appendChild(this._pageElem);
		}
		this._setSelfFocus();
	}

	/**
	 * Removes element from DOM and cancel focus on it
	 *
	 * @returns {void}
	 */
	deactivatePageElement() {
		if (this._pageElem && this._pageElem.parentNode) {
			this._pageElem.remove();
		}
		this._cancelSelfFocus();
	}

	/**
	 * Focuses this page element
	 *
	 * @returns {void}
	 * @protected
	 */
	_setSelfFocus() {
		this._cancelSelfFocus();
		focusedElementsStack.push(this);
	}

	/**
	 * Returns TRUE if this page element in focus
	 *
	 * @returns {boolean}
	 * @protected
	 */
	_isSelfFocus() {
		return FatalError.isShown === false
			&& Spinner.isShown === false
			&& focusedElementsStack.length > 0
			&& focusedElementsStack[focusedElementsStack.length - 1] === this;
	}

	/**
	 * Cancels focus on this page element
	 *
	 * @returns {void}
	 * @protected
	 */
	_cancelSelfFocus() {
		let i = focusedElementsStack.indexOf(this);
		if (i > -1) {
			focusedElementsStack.splice(i, 1);
		}
	}

	/**
	 * Destroys this page element
	 *
	 * @returns {void}
	 */
	destroy() {
		this._cancelSelfFocus();
		if (this._pageElem && this._pageElem.parentNode) {
			this._pageElem.remove();
		}
	}
}
