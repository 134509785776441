import PageElement from "ui/PageElement";
import Template from "ui/Template";
import {i18n} from "ui/i18n";
import dialogView from './dialog.handlebars';
import Spinner from "ui/components/spinner/Spinner";

const DEFAULT_WIDTH = 800;

/**
 * @type {Template}
 */
let template = null;

/**
 * @typedef {Object} T_DialogOptions
 * @property {string} title
 * @property {string|Element|PageElement} [content]
 * @property {boolean} [autoHeight] By default is ON
 * @property {boolean} [smallWidth]
 * @property {number} [width]
 * @property {boolean} [showTitle=true]
 * @property {boolean} [showCloseButton=true]
 * @property {boolean} [isAlert=false]
 * @property {Array<T_DialogButton>} [buttons]
 */

/**
 * @typedef {Object} T_DialogButton
 * @property {string} [icon]
 * @property {string} caption
 * @property {string} [role]
 */

/**
 * Dialog Box Controller
 */
export default class Dialog extends PageElement {
	/**
	 * @param {T_DialogOptions} options
	 */
	constructor(options) {
		super({parent: document.body});

		if (template === null) {
			template = new Template(dialogView);
		}

		this._element = template.createElement({
			title: options.title || i18n('title.dialog'),
			width: typeof options.width === 'number' ? options.width : DEFAULT_WIDTH,
			smallWidth: typeof options.smallWidth === 'boolean' ? options.smallWidth : false,
			autoHeight: typeof options.autoHeight === 'boolean' ? options.autoHeight : true,
			showButtons: options.buttons && options.buttons.length > 0,
			showTitle: typeof options.showTitle === 'boolean' ? options.showTitle : true,
			showCloseButton: typeof options.showCloseButton === 'boolean' ? options.showCloseButton : true,
			isAlert: typeof options.isAlert === 'boolean' ? options.isAlert : false,
			buttons: options.buttons || []
		});

		this._els = {
			body: this._element.querySelector('.dialog__body'),
			close: this._element.querySelector('.dialog__close-button'),
			buttons: this._element.querySelectorAll('.dialog__buttons .button'),
		};

		this._handlers = {
			keyDown: this._onKeyDown.bind(this)
		};

		Spinner.hide();

		if (this._els.close) {
			this._els.close.addEventListener('click', this.destroy.bind(this));
			document.addEventListener('keydown', this._handlers.keyDown);
		}

		let clickHandler = this._onButtonClick.bind(this);
		this._els.buttons.forEach((el) => {
			el.addEventListener('click', clickHandler);
		});

		this._els.body.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		this._element.addEventListener('click', (e) => {

		});

		this.activatePageElement();

		if (options.content) {
			this.setContent(options.content);
		}
	}

	/**
	 * @param {string|Element|PageElement} content
	 * @returns {Dialog}
	 */
	setContent(content) {
		if (content instanceof PageElement) {
			this._els.body.innerHTML = '';
			// noinspection JSAccessibilityCheck
			this._els.body.appendChild(content._element);
		} else if (typeof content === 'string') {
			this._els.body.innerHTML = content;
		} else {
			this._els.body.innerHTML = '';
			this._els.body.appendChild(content);
		}
		return this;
	}

	/**
	 * @param {Event} e
	 * @returns {void}
	 * @private
	 */
	_onButtonClick(e) {
		this._handleButton(e.target.closest('.button').getAttribute('data-role'));
	}

	/**
	 * @param {KeyboardEvent} e
	 * @private
	 */
	_onKeyDown(e) {
		if (e.code !== 'Escape' || !this._isSelfFocus()) {
			return;
		}

		if (e.target && 'tagName' in e.target && ['TEXTAREA', 'INPUT', 'SELECT'].includes(e.target.tagName)) {
			return;
		}

		this._handleButton('close');
	}

	/**
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		if (role === 'close' || role === '') {
			this.destroy();
		}
	}

	destroy() {
		document.removeEventListener('keydown', this._handlers.keyDown);
		super.destroy();
	}
}
