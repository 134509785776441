import headerView from "./html/header.handlebars";
import mainView from "./html/main.handlebars";
import iconsView from "./html/icons.handlebars";
import Template from "ui/Template";
import {
	CLIENT_MIN_VISIT_NUMBER_TO_RESTORE_POSITION_FROM_LS,
	IS_F796_RENDER_ENGINE,
	IS_SMALL_VIEW,
	LOW_BATTERY_LS_KEY, LOW_BATTERY_MSG_TIMEOUT,
	PROJECT_NAME, SCENE_FPS,
	ZOOM_STEP
} from "ui/config";
import {detectUserLanguage, i18n, LANG_EN, LANG_RU, switchUserLanguage} from "ui/i18n";
import SubsDialog from "ui/front/dialogs/subs/SubsDialog";
import Alert from "ui/dialogs/alert/Alert";
import ConfirmUnsubscribe from "ui/front/page/ConfirmUnsubscribe";
import ChangesBar, {
	EVENT_LOADED,
	EVENT_OPEN_INTERACTIVE_CHANGES,
	EVENT_SET_AREA,
	EVENT_SHARE
} from "ui/front/changes/ChangesBar";
import AboutDialog from "ui/front/dialogs/about/AboutDialog";
import ZoomController from "ui/helpers/ZoomController";
import {CurrentUser} from "ui/CurrentUser";
import UserMenu from "ui/components/user-menu/UserMenu";
import ChangesMap from "ui/front/changes/ChangesMap";
import {getCenterForPoints, getRectForPoints} from "ui/helpers/points";
import Utils from "ui/helpers/Utils";
import {validateEmail} from "ui/helpers/validators";
import UnsubsDialog from "ui/front/dialogs/subs/UnsubsDialog";
import InteractiveElementDialog from "ui/front/dialogs/interactive/InteractiveElementDialog";
import {EVENT_CANCEL_ITEM, EVENT_PICK_ITEM} from "ui/front/matrix/AbstractMatrixView";
import VideoMatrixView from "ui/front/matrix/VideoMatrixView";
import CanvasMatrixView from "ui/front/matrix/CanvasMatrixView";
import {detectLowBatteryStatus} from "ui/helpers/detectors";
import LowBatteryDialog from "ui/front/dialogs/low-battery/LowBatteryDialog";
import WebSocketService from "ui/front/websocket/WebSocketService";
import {TARGETS} from "ui/front/websocket/enum";
import ImageElementDialog from "ui/front/dialogs/image/ImageElementDialog";
import ClientSession from "ui/front/client/ClientSession";
import ClientActivity from "ui/front/client/ClientActivity";
import MatrixStat from "ui/front/matrix/MatrixStat";
import {EVENT_SLOW_MOVE_STARTED, EVENT_SLOW_MOVE_STOPPED} from "ui/front/matrix/MatrixViewPositionTransition";

const ITEM_AREA_INDENT = 10;
const ITEM_BORDER_SPACE = 60;

export default class FrontPage {
	constructor() {
		const user = CurrentUser.get();
		const headerHtml = new Template(headerView).createHtml({
			projectName: PROJECT_NAME,
			userName: user.userName,
			isUser: user.isUser(),
			isNewRender: IS_F796_RENDER_ENGINE,
		});
		const mainHtml = new Template(mainView).createHtml();

		document.body.innerHTML = headerHtml + mainHtml
			+ new Template(iconsView).createHtml();

		/**
		 * @type {AbstractMatrixView|null}
		 * @private
		 */
		this._matrix = null;

		/**
		 * @type {ChangesBar|null}
		 * @private
		 */
		this._changes = null;

		/**
		 * @type {ChangesMap|null}
		 * @private
		 */
		this._changesMap = null;

		/**
		 * @type {WebSocketService|null}
		 * @private
		 */
		this._wsService = null;

		/**
		 * @type {ClientSession|null}
		 * @private
		 */
		this._clientSession = null;

		/**
		 * @type {ClientActivity|null}
		 * @private
		 */
		this._clientActivity = null;

		/**
		 * @type {{item: T_UpdateOnFrontPage|null, selectedAt: number, center: string, from: string, to: string}}
		 * @private
		 */
		this._currentChangedArea = {
			item: null,
			selectedAt: 0,
			center: '',
			from: '',
			to: ''
		};

		/**
		 * @type {Map<string,{isLoop:boolean, sync:boolean, audio: HTMLAudioElement, justStarted: boolean}>}
		 * @private
		 */
		this._playingAudios = new Map();

		this._els = {
			body: document.body,
			area: document.querySelector('.change-area'),
			areaHead: document.querySelector('.change-area__head'),
			areaTitle: document.querySelector('.change-area__title'),
			areaLink: document.querySelector('.change-area__button'),
			areaInteractive: document.querySelector('.change-area__interactive'),
			areaInteractiveButton: document.querySelector('.change-area__interactive-button'),
			header: document.querySelector('.header'),
			aboutButton: document.querySelector('.header__button[data-role="about"]'),
			logoButton: document.querySelector('.header__logo'),
			userName: document.querySelector('.header__user-name'),
			userMenuButton: document.querySelector('.header__button[data-role="user-menu"]'),
			changesButton: document.querySelector('.header__button[data-role="changes"]'),
			changesCounter: document.querySelector('.header__button[data-role="changes"] .header__button-counter'),
			langEn: document.querySelector('.header__button[data-role="lang-en"]'),
			langRu: document.querySelector('.header__button[data-role="lang-ru"]'),
			subsButton: document.querySelector('.header__button[data-role="subs"]'),
			wanderingButton: document.querySelector('.header__button[data-role="wandering"]'),
		};

		this._init().then();
	}

	/**
	 * @return {WebSocketService|null}
	 */
	get wsService() {
		return this._wsService;
	}

	/**
	 * @return {ChangesBar|null}
	 */
	get changesBar() {
		return this._changes;
	}

	async _init() {
		this._initErrorHandler();
		this._initClient();
		this._initHeader();
		this._initChangesBar();
		await this._initMatrix();
		this._initChangesMap();

		this._matrix.play();

		await this._showInitModals();
		this._matrix.enableDraggingTool();
		this._matrix.enableZoomFactorSwitcher();

		await this._initWsService();

		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				this._stopAudio();
			}
		});
	}

	/**
	 * @private
	 */
	_initClient() {
		this._clientSession = new ClientSession();
		this._clientActivity = new ClientActivity();
	}

	/**
	 * @private
	 */
	_initHeader() {
		this._els.subsButton.onclick = async () => {
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.DIALOG_SUBSCRIBE);
			await this._showSubsDialog();
		};

		this._els.logoButton.onclick =
		this._els.aboutButton.onclick = () => {
			this._hideChangeArea();
			this._hideChangesBar();
			new AboutDialog(this._matrix, this._wsService.stat);
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.DIALOG_ABOUT);
			this._matrix.position.transition.stop();
		};

		this._els.changesButton.onclick = () => {
			this._changes.toggle();
			this._els.changesCounter.textContent = '';
			this._els.changesButton.classList.toggle('header__button_pressed', this._changes.isOpen);
			this._hideChangeArea();
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.CHANGES_BAR);
			this._matrix.position.transition.stop();
		};

		this._els.userMenuButton.onclick = () => {
			new UserMenu(this._els.userMenuButton);
		};

		this._els.wanderingButton.onclick = () => {
			if (this._els.wanderingButton.dataset.value === 'on') {
				this._matrix.position.transition.stopRandomMove();
				this._els.wanderingButton.dataset.value = 'off';
			} else {
				this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.WANDERING);
				this._matrix.position.transition.startRandomMove();
				this._els.wanderingButton.dataset.value = 'on';
			}
		};

		CurrentUser.get().addObserver(() => {
			const user = CurrentUser.get();
			this._els.userMenuButton.hidden = !user.isUser();
			this._els.userName.textContent = user.userName;
		});

		this._initLangButtons();
	}

	/**
	 * @private
	 */
	_initLangButtons() {
		const lang = detectUserLanguage();
		if (lang === LANG_EN) {
			this._els.langEn.classList.add('header__button_pressed');
		} else if (lang === LANG_RU) {
			this._els.langRu.classList.add('header__button_pressed');
		}

		this._els.langEn.onclick = () => {
			if (lang === LANG_EN) {
				return;
			}
			switchUserLanguage(LANG_EN);
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.LANG_SWITCH);
			window.location.reload();
		};

		this._els.langRu.onclick = () => {
			if (lang === LANG_RU) {
				return;
			}
			switchUserLanguage(LANG_RU);
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.LANG_SWITCH);
			window.location.reload();
		};
	}

	/**
	 * @return {Promise<void>}
	 * @private
	 */
	async _initMatrix() {
		const cls = IS_F796_RENDER_ENGINE ? CanvasMatrixView : VideoMatrixView;
		let zoomFactor = IS_SMALL_VIEW ? 0.7 : 1;

		const hashParams = window.location.hash.match(/^#?[tb]\d+[lr]\d+,\d+,\d+(,(\d{1,2}))$/);
		if (hashParams && hashParams[2] === '1') {
			zoomFactor = 1;
		}

		this._matrix = new cls({
			parent: document.querySelector('main'),
			//useQualityPreview: true,
			restorePositionFromUrl: true,
			restorePositionFromLS: false,
				//this._clientSession.visitNumber >= CLIENT_MIN_VISIT_NUMBER_TO_RESTORE_POSITION_FROM_LS,
			zoomFactor,
			onFrame: (frame) => {
				if (frame === 0) {
					for (const {isLoop, sync, audio} of this._playingAudios.values()) {
						if (isLoop && sync) {
							audio.currentTime = 0;
						}
					}
				}
			},
			/**
			 * @param {T_MatrixData} data
			 */
			onMatrixLoaded: (data) => {
				new MatrixStat(data).print();

				if (data.updates && data.updates.length > 0) {
					this._els.changesButton.title = i18n('messages.last-update-date', data.updates[0]);
					const lastViewedDate = this._changes.getLastViewedChangeDate();
					const changes = data.updates.reduce((acc, date) => date > lastViewedDate ? acc + 1 : acc, 0);
					if (changes > 0) {
						this._els.changesCounter.textContent = String(changes);
					}
				}
			}
		});
		await this._matrix.build();

		this._matrix.position.addObserver(this._updateChangeArea.bind(this));
		this._matrix.position.transition.events.addListener(EVENT_SLOW_MOVE_STARTED, () => {
			this._hideChangesBar();
			this._hideChangeArea();
			this._els.header.classList.add('header_hide');
			window.location.hash = '#wandering';
		});

		this._matrix.position.transition.events.addListener(EVENT_SLOW_MOVE_STOPPED, () => {
			this._els.header.classList.remove('header_hide');
			this._matrix.position.savePosition();
		});
	}

	/**
	 * @private
	 */
	_initChangesBar() {
		this._changes = new ChangesBar({
			parent: this._els.body,
		});

		this._changes.events.addListener(EVENT_OPEN_INTERACTIVE_CHANGES, async () => {
			this._wsService && this._wsService.stat.saveClickByTarget(TARGETS.INTERACTIVE_CHANGES);
		});

		this._changes.events.addListener(EVENT_LOADED, async (changes) => {
			if (this._changesMap) {
				this._changesMap.setItems(changes);
			}
		});

		this._changes.events.addListener(EVENT_SET_AREA, async (item) => {
			this._stopAudio();

			for (const url of item.l.split('||')) {
				if (/^play(-loop(-no-sync)?)?:\/\//.test(url)) {
					this._playUrl(url).then();
				} else if (url.startsWith('event://')) {
					this._dispatchEventFromUrl(url);
				}
			}

			await this._selectItem(item, true);
		});

		this._changes.events.addListener(EVENT_SHARE, (type) => {
			const types = {
				'tg': TARGETS.LINK_TELEGRAM,
				'x': TARGETS.LINK_TWITTER,
				'artstation': TARGETS.LINK_ARTSTATION,
			};
			this._wsService && types[type] && this._wsService.stat.saveClickByTarget(types[type]);
		});

		new ZoomController({
			target: this._els.area,
			stepSize: ZOOM_STEP,
			callback: (delta, isWheel, x, y, isCtrl) => {
				this._matrix.changeZoomFactorByDelta(delta, isWheel, x, y, isCtrl);
				if (this._currentChangedArea.item !== null) {
					this._matrix.position.setPositionCode(this._currentChangedArea.center);
					this._updateChangeArea();
				}
			}
		});

		this._els.area.onclick = this._hideChangeArea.bind(this);
		this._els.areaHead.onmouseup =
		this._els.areaHead.onmousedown =
		this._els.areaHead.onclick = (e) => {
			e.stopPropagation();
		};
		this._els.areaInteractiveButton.onclick = async () => {
			this._hideChangeArea();
			this._hideChangesBar();
			this._matrix.stop();
			new InteractiveElementDialog(this._els.areaInteractive.dataset.title, this._els.areaInteractive.dataset.url)
				.getClosePromise().then(() => {
					this._matrix.play();
				});
		};
	}

	/**
	 * @private
	 */
	_initChangesMap() {
		this._changes.load().then();
		this._changesMap = new ChangesMap(this._matrix);

		if (Array.isArray(this._changes.records)) {
			this._changesMap.setItems(this._changes.records);
		}

		this._matrix.events.addListener(EVENT_PICK_ITEM, async ({x, y}) => {
			if (!Array.isArray(this._changes.records)) {
				return;
			}

			document.dispatchEvent(
				new CustomEvent('interact', {detail: this._matrix.position.getPositionCode(x, y)})
			);

			const item = this._changesMap.getItemAtPosition(x, y);

			this._stopAudio();
			this._matrix.position.transition.stop();

			if (item) {
				if (item === this._currentChangedArea.item) {
					if (Date.now() - this._currentChangedArea.selectedAt < 300) {
						// double click, just ignoring
						return;
					} else {
						this._hideChangeArea();
						return;
					}
				}

				if (this._wsService) {
					this._wsService.stat.saveClickByItem(item.id);
				}

				for (const url of item.l.split('||')) {
					if (/^play(-loop(-no-sync)?)?:\/\//.test(url)) {
						this._playUrl(url);
					} else if (/^img:\/\//.test(url)) {
						new ImageElementDialog(item.t, url.replace(/^img:\/\//, '/'));
						this._hideChangeArea();
					} else if (/^event:\/\//.test(url)) {
						this._dispatchEventFromUrl(url);
						this._hideChangeArea();
					} else {
						await this._selectItem(item);
					}
				}
			} else {
				this._hideChangeArea();
			}
		});

		this._matrix.events.addListener(EVENT_CANCEL_ITEM, () => {
			this._hideChangeArea();
		});

		document.addEventListener('select-custom-item', /** @param {CustomEvent} e */(e) => {
			this._selectItem(e.detail).then();
		})
	}

	/**
	 * @param {string} url event://event-name?url-params
	 * @private
	 */
	_dispatchEventFromUrl(url) {
		const parts = url.replace(/^event:\/\//, '').split('?');

		window.__lastCustomEvent = new CustomEvent(parts[0], {detail: parts[1] || ''});
		document.dispatchEvent(window.__lastCustomEvent);
	}

	/**
	 * @param {string} srcUrl
	 * @return {Promise<void>}
	 * @private
	 */
	async _playUrl(srcUrl) {
		const [, scheme, , , url] = srcUrl.match(/(play(-loop(-no-sync)?)?):\/\/([^|]+)/);

		if (this._playingAudios.has(url)) {
			return;
		}

		const isLoop = scheme === 'play-loop' || scheme === 'play-loop-no-sync';
		const sync = scheme === 'play-loop';

		const audio = new Audio('/' + url);

		this._playingAudios.set(url, {isLoop, sync, audio, justStarted: true});
		setTimeout(() => {
			const props = this._playingAudios.get(url);
			if (props) {
				props.justStarted = false;
			}
		}, 70);

		audio.volume = 0.5;
		audio.onended = () => this._playingAudios.delete(url);

		if (isLoop && IS_F796_RENDER_ENGINE) {
			audio.loop = true;

			if (sync) {
				audio.currentTime = this._matrix.currentFrame / SCENE_FPS;
			}
		}

		await audio.play();
	}

	/**
	 * @private
	 */
	_stopAudio() {
		for (const [url, props] of this._playingAudios.entries()) {
			if (!props.justStarted) {
				props.audio.pause();
				this._playingAudios.delete(url);
			}
		}
	}

	/**
	 * @param {T_UpdateOnFrontPage} item
	 * @param {boolean} [scrollToItem=false]
	 * @private
	 */
	async _selectItem(item, scrollToItem = false) {
		if (!this._matrix || item.p === '') {
			return;
		}

		const isSelected = this._currentChangedArea.item !== null;
		this._currentChangedArea.item = null;

		const pageWidth = window.innerWidth || document.documentElement.clientWidth;
		if (pageWidth < 1024) {
			this._hideChangesBar();
		}

		const area = this._els.area;

		const pts = item.p.split(';')
			.map(p => this._matrix.position.parsePositionCode(p))
			.filter(point => point !== null);

		const rect = getRectForPoints(pts);
		const centerPt = getCenterForPoints(pts);
		const center = this._matrix.position.getPositionCode(centerPt.x, centerPt.y);
		const from = this._matrix.position.getPositionCode(rect.left, rect.top);
		const to = this._matrix.position.getPositionCode(rect.right, rect.bottom);

		if (scrollToItem) {
			if (!await this._matrix.position.transition.moveQuick(center)) {
				return;
			}
			this._matrix.position.savePosition();
		} else {
			let correctX = 0, correctY = 0;
			const borderSpace2x = ITEM_BORDER_SPACE << 1;

			if (rect.width > this._matrix.position.width - borderSpace2x) {
				correctX = ((this._matrix.position.width - rect.width) / 2) - rect.left;
			} else {
				if (rect.left < ITEM_BORDER_SPACE) {
					correctX = ITEM_BORDER_SPACE - rect.left;
				}

				if (rect.right > this._matrix.position.width - ITEM_BORDER_SPACE) {
					correctX = (this._matrix.position.width - ITEM_BORDER_SPACE) - rect.right;
				}
			}

			if (rect.top < borderSpace2x) {
				correctY = borderSpace2x - rect.top;
			}

			if (correctX !== 0 || correctY !== 0) {
				const code = this._matrix.position.getPositionCode(
					(this._matrix.position.width >> 1) - correctX,
					(this._matrix.position.height >> 1) - correctY
				);

				if (code !== '') {
					if (!await this._matrix.position.transition.moveQuick(code)) {
						return;
					}
					this._matrix.position.savePosition();
				}
			}
		}

		this._currentChangedArea.selectedAt = Date.now();
		this._currentChangedArea.item = item;
		this._currentChangedArea.center = center;
		this._currentChangedArea.from = from;
		this._currentChangedArea.to = to;

		if (!isSelected) {
			await this._resetAreaPosition();
		}

		this._updateChangeArea();

		if (/@\w/.test(item.t)) {
			this._els.areaTitle.innerHTML = item.t.replace(/@[\w.-]+/, (text) => {
				return `<a href="https://pikabu.ru/${text}" target="_blank">${text}</a>`;
			});
		} else {
			this._els.areaTitle.textContent = item.t;
		}

		const isInteractive = /^interactive:\/\//.test(item.l);
		this._els.areaLink.setAttribute('href', item.l.includes('https://') ? item.l.match(/https:\/\/[^|]+/)[0] : '#');
		this._els.areaLink.hidden = !item.l || isInteractive;
		this._els.areaInteractive.hidden = !isInteractive;
		this._els.areaLink.onclick = () => {
			if (this._wsService && item.id) {
				this._wsService.stat.saveClickByItemSource(item.id);
			}
		};

		if (isInteractive) {
			this._els.areaInteractive.setAttribute('data-title', item.t);
			this._els.areaInteractive.setAttribute(
				'data-url',
				'/interactive/' + item.l.match(/^interactive:\/\/([^|]+)(\|\||$)/)[1]
			);
		}

		window.__selectedItem = {
			item,
			titleEl: this._els.areaTitle,
			linkEl: this._els.areaLink,
		};
		document.dispatchEvent(new CustomEvent('select-item', {
			detail: window.__selectedItem
		}));

		area.classList.add('change-area_show');

		if (this._wsService && item.id) {
			this._wsService.stat.saveClickByItem(item.id);
		}
	}

	/**
	 * @private
	 */
	_updateChangeArea() {
		if (this._currentChangedArea.item === null) {
			return;
		}

		const area = this._els.area;
		const {x: x1, y: y1} = this._matrix.position.parsePositionCode(this._currentChangedArea.from);
		const {x: x2, y: y2} = this._matrix.position.parsePositionCode(this._currentChangedArea.to);

		area.style.setProperty('--x1', `${x1 - ITEM_AREA_INDENT}px`);
		area.style.setProperty('--x2', `${x2 + ITEM_AREA_INDENT}px`);
		area.style.setProperty('--y1', `${y1 - ITEM_AREA_INDENT}px`);
		area.style.setProperty('--y2', `${y2 + ITEM_AREA_INDENT}px`);
	}

	async _resetAreaPosition() {
		if (this._currentChangedArea.item === null) {
			return;
		}

		const area = this._els.area;
		const {x: x1, y: y1} = this._matrix.position.parsePositionCode(this._currentChangedArea.from);
		const {x: x2, y: y2} = this._matrix.position.parsePositionCode(this._currentChangedArea.to);

		area.classList.remove('change-area_animated');
		area.style.setProperty('--x1', `${x1 + ((x2 - x1) >> 1)}px`);
		area.style.setProperty('--x2', `${x1 + ((x2 - x1) >> 1)}px`);
		area.style.setProperty('--y1', `${y1 + ((y2 - y1) >> 1)}px`);
		area.style.setProperty('--y2', `${y1 + ((y2 - y1) >> 1)}px`);

		await Utils.wait(50);
		area.classList.add('change-area_animated');
	}

	/**
	 * @private
	 */
	_hideChangesBar() {
		this._changes.toggle(false);
		this._els.changesButton.classList.toggle('header__button_pressed', false);
	}

	/**
	 * @private
	 */
	_hideChangeArea() {
		this._stopAudio();
		window.__selectedItem = null;
		document.dispatchEvent(new CustomEvent('unselect-item', {
			detail: {
				item: this._currentChangedArea.item,
				titleEl: this._els.areaTitle,
				linkEl: this._els.areaLink,
			}
		}));

		this._els.area.classList.remove('change-area_show');
		this._currentChangedArea.item = null;
	}

	/**
	 * @private
	 */
	_initErrorHandler() {
		return;
		if (!CurrentUser.get().isAdmin()) {
			return;
		}

		window.onerror = (errorMsg) => {
			alert(errorMsg);
		};

		window.addEventListener('unhandledrejection', (e) => {
			alert(e.reason);
		});
	}

	/**
	 * @return {Promise<void>}
	 * @private
	 */
	async _initWsService() {
		this._wsService = new WebSocketService(this._clientSession, this._clientActivity);
		await this._wsService.init();
		this._wsService.stat.watchChangesInViewport(this._matrix.position, this._changesMap);
	}

	/**
	 * @return {Promise<void>}
	 * @private
	 */
	async _showInitModals() {
		if (
			await detectLowBatteryStatus()
			&& parseInt(window.localStorage.getItem(LOW_BATTERY_LS_KEY) || '0') < Date.now() - LOW_BATTERY_MSG_TIMEOUT
		) {
			await new LowBatteryDialog().confirm();
			window.localStorage.setItem(LOW_BATTERY_LS_KEY, String(Date.now()));
		}

		const path = window.location.hash;
		if (/#goodbye\/([a-f0-9]{32})\/([^\/]+)$/.test(path)) {
			let [, token, email] = path.match(/#goodbye\/([a-f0-9]{32})\/([^\/]+)$/);
			email = decodeURIComponent(email);
			if (validateEmail(email) === '') {
				const res = await new UnsubsDialog(email, token).getResultPromise();
				if (typeof res === 'boolean') {
					await new Alert(i18n('messages.confirm-unsub-success', email)).getResult();
				}
				window.location.hash = '';
				return;
			}
		}

		if (ConfirmUnsubscribe.isConfirmPath(path)) {
			await new ConfirmUnsubscribe().process(path);
			window.location.hash = '';
			return;
		}

		if (/#subscribe\/([^\/]+)\/([^\/]+)$/.test(path)) {
			await new Alert(
				i18n('messages.confirm-subs-deprecated'),
				'error'
			).getResult();
			window.location.hash = '';
		}

		if (path === '#wandering' && !IS_SMALL_VIEW) {
			this._matrix.position.transition.startRandomMove();
			this._els.wanderingButton.dataset.value = 'on';
		}
	}

	/**
	 * @param {string} [email]
	 * @return {Promise<void>}
	 * @private
	 */
	async _showSubsDialog(email = '') {
		const res = await new SubsDialog(this._wsService.stat, email).getResultPromise();
		if (typeof res === 'boolean') {
			await new Alert(
				i18n(res ? 'messages.confirm-subs' : 'messages.confirm-unsub'),
				res ? '' : 'mail'
			).getResult();
		}
	}
}
