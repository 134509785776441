import {MIN_ALLOWED_BATTERY_LEVEL} from "ui/config";

export function detectMobileScreen() {
	return window.devicePixelRatio > 1
		&& Math.min(screen.width, screen.height) < 600;
}

export function detectWorkerAndWasmSupport() {
	return ('Worker' in window)
		&& (typeof WebAssembly === 'object')
		&& ('createImageBitmap' in window);
}

export async function detectLowBatteryStatus() {
	try {
		if (!('getBattery' in navigator)) {
			return false;
		}

		const battery = await navigator.getBattery();
		return battery.level < MIN_ALLOWED_BATTERY_LEVEL && !battery.charging;
	} catch (e) {
		return false;
	}
}

export function detectWebpSupport() {
	const flag = localStorage.getItem('webp');
	if (typeof flag === 'string') {
		return flag === '1';
	}

    const isSupported = document.createElement('canvas')
	    .toDataURL('image/webp')
	    .indexOf('data:image/webp') === 0;

	localStorage.setItem('webp', isSupported ? '1' : '0');
	return isSupported;
}
