const templateEl = document.createElement('template');

/**
 * Template Adapter for Handlebars templates engine
 */
export default class Template {
	/**
	 * Class constructor
	 *
	 * @param {Function} tpl
	 */
	constructor(tpl) {
		/**
		 * @private
		 * @type {Function}
		 */
		this._template = tpl;
	}

	/**
	 * Creates HTML Element by precompiled template
	 *
	 * @param {Object} data
	 * @returns {HTMLElement}
	 */
	createElement(data = {}) {
		templateEl.innerHTML = this.createHtml(data);
		return templateEl.content.cloneNode(true).firstElementChild;
	}

	/**
	 * Creates HTML by precompiled template
	 *
	 * @param {Object} data
	 * @returns {string}
	 */
	createHtml(data = {}) {
		return this._template(data, {allowProtoPropertiesByDefault: true});
	}
}
