/**
 * @param {number} size
 * @return {string}
 */
export function formatFileSize(size) {
	if (size <= 0) {
		return size + 'B';
	}

	const i = Math.floor(Math.log(size) / Math.log(1024));
	return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function formatDateTime(value) {
	const dt = new Date(value);
	return String(dt.getDate()).padStart(2, '0')
		+ '.' + String(dt.getMonth() + 1).padStart(2, '0')
		+ '.' + String(dt.getFullYear()).replace(/^20/, '').padStart(2, '0')
		+ ' ' + String(dt.getHours()).padStart(2, '0')
		+ ':' + String(dt.getMinutes()).padStart(2, '0');
}

export function formatDateOnly(value) {
	const dt = new Date(value);
	return String(dt.getDate()).padStart(2, '0')
		+ '.' + String(dt.getMonth() + 1).padStart(2, '0')
		+ '.' + String(dt.getFullYear()).padStart(4, '0');
}

export function formatDateOnlyYmd(value) {
	const dt = new Date(value);
	return String(dt.getFullYear()).padStart(4, '0')
		+ '-' + String(dt.getMonth() + 1).padStart(2, '0')
		+ '-' + String(dt.getDate()).padStart(2, '0');
}

export function formatTimeInterval(interval) {
	let out = [];
	const days = Math.floor(interval / (3600 * 24));
	if (days > 0) {
		out.push(`${days}d`);
		interval -= days * 3600 * 24;
	}

	const hours = Math.floor(interval / 3600);
	if (hours > 0) {
		out.push(`${hours}h`);
		interval -= hours * 3600;
	}

	const minutes = Math.floor(interval / 60);
	out.push(`${minutes}m`);
	interval -= minutes * 60;

	out.push(`${interval}s`);

	return out.join(' ');
}

export function escapeHtml(value) {
	if (typeof value !== 'string') {
		return value;
	}

	return value.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&apos;');
}

export function formatLinksFromMarkdown(value) {
	value = String(value);
	return value.replace(/\[([^\]]+)]\(([^)]+)\)/g, (tmp, text, url) => {
		if (/^https?:/.test(url)) {
			return `<a href="${url}" rel="nofollow" target="_blank">${text}</a>`;
		} else if (/\w+@\w+\.\w+$/.test(url)) {
			return `<a href="mailto:${url}" target="_blank">${text}</a>`;
		}
		return `<a href="${url}" target="_blank">${text}</a>`;
	});
}

export function formatHtmlFromMarkdown(value) {
	value = String(value);
	value = value.replace(/\n\n/g, '<br><br>');
	value = formatLinksFromMarkdown(value);
	return value;
}

export function formatHtmlToPlainText(value) {
	value = String(value);
	value = value.replace(/<br\s*\/?>/g, '\n');
	value = value.replace(/<\/(p|div|blockquote|section)>/g, '\n');
	value = value.replace(/<!?[a-z][^>]*>/g, '');
	return value;
}

/**
 * @param {number} num
 * @return {number[]}
 */
export function packUint32(num) {
	return [
		(num >> 24) & 0xFF,
		(num >> 16) & 0xFF,
		(num >> 8) & 0xFF,
		num & 0xFF
	];
}

/**
 * @param {number} num
 * @return {number[]}
 */
export function packUint16(num) {
	return [
		(num >> 8) & 0xFF,
		num & 0xFF
	];
}

/**
 * @param {number[]} arr
 * @return {number[]}
 */
export function packUint16Array(arr) {
	const out = [];
	out.push(...packUint16(arr.length));

	for (const val of arr) {
		out.push(...packUint16(val));
	}

	return out;
}

export function packString(str) {
	const uint8Arr = new TextEncoder().encode(str);
	return [...packUint16(uint8Arr.length), ...uint8Arr];

}
