import Template from "ui/Template";
import Ajax from "ui/helpers/Ajax";
import {i18n} from "ui/i18n";
import Dialog from "ui/elements/dialog/Dialog";
import Utils from "ui/helpers/Utils";
import Form from "ui/elements/form/Form";
import loginFormView from './login-form.handlebars';
import Spinner from "ui/components/spinner/Spinner";
import FatalError from "ui/components/fatal-error/FatalError";
import SignupForm from "ui/components/signup-form/SignupForm";
import {CurrentUser} from "ui/CurrentUser";

export default class LoginForm extends Dialog {
	constructor() {
		super({
			title: i18n('title.auth'),
			smallWidth: true,
			showCloseButton: false,
			buttons: [
				{
					caption: i18n('buttons.signin'),
					role: 'login'
				},
				/*{
					caption: i18n('buttons.create-account'),
					role: 'create'
				},*/
				{
					caption: i18n('buttons.cancel'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {function()|null}
		 * @private
		 */
		this._onComplete = null;

		/**
		 * @type {Form}
		 * @private
		 */
		this._form = null;

		void this._buildForm();
		this.activatePageElement();

		Spinner.hide();
	}

	async getResult() {
		return new Promise(r => this._onComplete = r);
	}

	/**
	 * Handles click by button
	 *
	 * @override
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		super._handleButton(role);
		if (role === 'login') {
			this._submit().then();
		} else if (role === 'create') {
			new SignupForm().getResult().then(res => {
				if (res) {
					if (CurrentUser.get().isUser()) {
						if (this._onComplete) {
							this._onComplete(true);
							this._onComplete = null;
						}
						this.destroy();
					}
				}
			});
		}
	}

	/**
	 * Validates and submit login form
	 *
	 * @private
	 */
	async _submit() {
		const data = this._form.getValues();
		if (data === null) {
			return;
		}
		const post = {
			l: data.login,
			p: data.password,
			r: data.remember ? '1' : '0'
		};

		Spinner.show();

		try {
			const res = await Ajax.post('/user/login', post);
			Spinner.hide();
			if (res.result) {
				CurrentUser.get().readCookies();

				if (this._onComplete) {
					this._onComplete(true);
					this._onComplete = null;
				}
				this.destroy();
			} else {
				this._form.showError(i18n('errors.user.auth'), ['login', 'password']);
			}
		} catch (e) {
			Spinner.hide();
			FatalError.show(e || i18n('errors.http.error'));
		}
	}

	/**
	 * Builds form element
	 *
	 * @return {Promise<void>}
	 * @private
	 */
	async _buildForm() {
		let el = new Template(loginFormView).createElement({});
		this._form = new Form({
			parent: el,
			onApply: this._submit.bind(this),
			values: {remember: true},
			fields: [
				{
					type: 'text',
					name: 'login',
					caption: i18n('inputs.signin.login'),
					required: true,
					maxlength: 32,
					validators: []
				},
				{
					type: 'password',
					name: 'password',
					caption: i18n('inputs.signin.password'),
					required: true,
					maxlength: 256,
					validators: []
				},
				{
					type: 'checkbox',
					name: 'remember',
					caption: i18n('inputs.signin.remember')
				}
			]
		});
		this.setContent(el);
		// focus first input
		await Utils.wait(40);
		el.querySelector('input').focus();
	}

	/**
	 * Destroys the login form
	 *
	 * @returns {void}
	 */
	destroy() {
		super.destroy();
		if (this._onComplete) {
			this._onComplete(false);
			this._onComplete = null;
		}
	}
}
