import {IS_F796_RENDER_ENGINE} from "ui/config";
import {formatFileSize} from "ui/helpers/formatters";

export default class MatrixStat {
	/**
	 * @param {T_MatrixData} mat
	 */
	constructor(mat) {
		/**
		 * @type {T_MatrixData}
		 * @private
		 */
		this._data = mat;
	}

	/**
	 * @return {{mp4: number, f796: number}}
	 */
	get formatsSize() {
		let totalSizeByFormats = {
			mp4: 0,
			f796: 0,
		};
		const mat = this._data.mat;

		for (let r = 0; r < mat.length; r++) {
			for (let c = 0; c < mat[r].length; c++) {
				const cell = mat[r][c];
				if (!cell.video_size) {
					continue;
				}
				totalSizeByFormats.mp4 += cell.video_size.mp4;
				totalSizeByFormats.f796 += cell.video_size.f796.reduce((acc, v) => acc + v, 0);
			}
		}

		return totalSizeByFormats;
	}

	print() {
		const sizes = this.formatsSize;
		console.log('Current render format: ' + (IS_F796_RENDER_ENGINE ? 'F796 (best quality)' : 'MP4'));
		console.log('Summary size of MP4 format: ' + formatFileSize(sizes.mp4));
		console.log('Summary size of F796 format: ' + formatFileSize(sizes.f796));
	}
}