
/**
 * @param {{x:number,y:number}[]} points
 * @return {{top: number, left: number, bottom: number, width: number, right: number, height: number}}
 */
export function getRectForPoints(points) {
	let left = Number.MAX_VALUE,
		top = Number.MAX_VALUE,
		right = -Number.MAX_VALUE,
		bottom = -Number.MAX_VALUE;

	if (points.length === 0) {
		return {
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
			width: 0,
			height: 0,
		};
	}

	for (const {x, y} of points) {
		left = left < x ? left : x;
		top = top < y ? top : y;
		right = right > x ? right : x;
		bottom = bottom > y ? bottom : y;
	}

	return {
		left,
		top,
		right,
		bottom,
		width: right - left,
		height: bottom - top,
	};
}

/**
 * @param {{x:number,y:number}[]} points
 * @return {{x: number, y: number}}
 */
export function getCenterForPoints(points) {
	const {left, top, width, height} = getRectForPoints(points);
	return {
		x: left + (width >> 1),
		y: top + (height >> 1),
	};
}
