import {detectUserLanguage, LANG_DEFAULT} from "ui/i18n";
import Ajax from "ui/helpers/Ajax";

/**
 * @type {T_UpdateOnFrontPage[]|null}
 */
let cachedChanges = null;

/**
 * @type {Promise<boolean>}
 */
let loadPromise = null;

export default class ChangesLoader {
	constructor() {
		/**
		 * @type {T_UpdateOnFrontPage[]}
		 * @private
		 */
		this._changes = cachedChanges || [];
	}

	/**
	 * @return {T_UpdateOnFrontPage[]}
	 */
	get changes() {
		return this._changes;
	}

	/**
	 * @return {Promise<boolean>}
	 */
	async load() {
		if (cachedChanges !== null) {
			this._changes = cachedChanges;
			return true;
		}

		if (loadPromise) {
			return loadPromise;
		}

		const lang = detectUserLanguage();
		let url = '/data/changelog.json';

		if (lang !== LANG_DEFAULT && lang !== '') {
			url = '/data/changelog-' + lang + '.json'
		}

		loadPromise = new Promise(async r => {
			try {
				// noinspection JSValidateTypes
				cachedChanges = await Ajax.get(url + '?r=' + Math.random());
			} catch (e) {
				r(false);
			}

			r(true);
		})

		await loadPromise;

		this._changes = cachedChanges;

		return true;
	}
}
