var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"list__button\" href=\""
    + alias2(__default(require("../../../handlebars-helpers/any.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),"#",{"name":"any","hash":{},"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":47}}}))
    + "\" title=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":56},"end":{"line":1,"column":65}}}) : helper)))
    + "\">\n	<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon\"><use href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":2,"column":69},"end":{"line":2,"column":77}}}) : helper)))
    + "\" /></svg>\n</a>\n";
},"useData":true});