import fatalErrorView from './fatal-error.handlebars';
import Template from "ui/Template";

/**
 * @type {boolean}
 */
let isFatalError = false;

export default class FatalError {
	/**
	 * @returns {boolean}
	 */
	static get isShown() {
		return isFatalError;
	}

	/**
	 * @param {string} message
	 * @param {string} backURL
	 * @param {function(): void} onCloseCallback
	 */
	static show(message, backURL = '', onCloseCallback = null) {
		const el = new Template(fatalErrorView)
			.createElement({backURL, message});

		const button = el.querySelector('button[data-role="close"]');
		if (button) {
			button.addEventListener('click', () => {
				isFatalError = false;
				document.body.removeChild(el);
				if (onCloseCallback) {
					onCloseCallback();
				}
			});
		}

		document.body.appendChild(el);
	}
}
