import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import aboutTemplate from "./about.handlebars";
import Template from "ui/Template";
import {STATIC_URL} from "ui/config";
import DownloadAsImageDialog from "ui/front/dialogs/download-as-image/DownloadAsImageDialog";
import {TARGETS} from "ui/front/websocket/enum";

export default class AboutDialog extends Dialog {

	/**
	 * @param {AbstractMatrixView} matrix
	 * @param {StatClient} stat
	 */
	constructor(matrix, stat) {
		super({
			title: i18n('title.about'),
			width: 800,
			content: new Template(aboutTemplate).createHtml({
				staticUrl: STATIC_URL
			}),
			buttons: [
				{
					caption: i18n('buttons.close'),
					role: 'close'
				}
			]
		});

		this._element.querySelector('.about__body').addEventListener('click', (e) => {
			if (!(e.target instanceof HTMLAnchorElement)) {
				return;
			}

			const url = e.target.href;
			if (url.includes('editor/l0')) {
				stat.saveClickByTarget(TARGETS.LINK_EDITOR);
			} else if (url.includes('www.youtube.com/channel')) {
				stat.saveClickByTarget(TARGETS.LINK_YOUTUBE);
			} else if (url.includes('habr.com')) {
				stat.saveClickByTarget(TARGETS.LINK_HABR);
			} else if (url.includes('artstation')) {
				stat.saveClickByTarget(TARGETS.LINK_ARTSTATION);
			}
		});

		this._element.querySelector('button[data-role="download-as-image"]').onclick = () => {
			new DownloadAsImageDialog(matrix);
			stat.saveClickByTarget(TARGETS.DIALOG_DOWNLOAD_IMAGES);
		};

		/*this._element.querySelector('button[data-role="timelapse"]').onclick = () => {
			let file = matrix.matrixData.timelapse.file;

			if (!file) {
				return;
			}

			file = '/data/timelapse/fin/' + file;

			if (IS_SMALL_VIEW) {
				window.open(file);
				return;
			}

			matrix.stop();
			new TimelapseDialog(file).getClosePromise().then(() => {
				matrix.play();
			});
		};*/
	}
}
