import Template from "ui/Template";
import {i18n} from "ui/i18n";
import formView from './form.handlebars';
import EventDispatcher from "ui/EventDispatcher";

export const EVENT_FIELD_CHANGE = 'EVENT_FIELD_CHANGE';

/**
 * @typedef {T_PageElementOptions} T_FormOptions
 * @property {string} [message]
 * @property {Array<T_FormField>} fields
 * @property {?Object} [values]
 * @property {?function(data:Object)} [onApply]
 */

/**
 * @typedef {Object} T_FormField
 * @property {string} caption
 * @property {string} type
 * @property {string} [name]
 * @property {string} [modifier]
 * @property {boolean} [fullWidth]
 * @property {boolean} [fullHeight]
 * @property {number} [maxlength]
 * @property {boolean} [required]
 * @property {boolean} [readonly]
 * @property {string} [placeholder]
 * @property {string} [hint]
 * @property {Array<T_FormFieldOption>} [options]
 * @property {Array<function(name:string):string|undefined>} [validators]
 * @property {function(data:object):boolean} [disableIf]
 */

/**
 * @typedef {Object} T_FormFieldOption
 * @property {string} value
 * @property {string} text
 */

/**
 * @type {Template}
 */
let template = null;

/**
 * Form Element
 */
export default class Form {
	/**
	 * @param {T_FormOptions} options
	 */
	constructor(options) {
		if (template === null) {
			template = new Template(formView);
		}

		/**
		 * @type {T_FormOptions}
		 * @private
		 */
		this._options = options;

		/**
		 * @type {EventDispatcher}
		 * @private
		 */
		this._events = new EventDispatcher();

		this._els = {
			error: null,
			note: null,
			groups: {}
		};

		if (options.parent) {
			options.parent.appendChild(this.getElement());
		}
		if (options.values) {
			this.setValues(options.values);
		}
	}

	/**
	 * @return {EventDispatcher}
	 */
	get events() {
		return this._events;
	}

	/**
	 * @returns {Element}
	 */
	getElement() {
		if (!this._el) {
			this.render();
		}

		return this._el;
	}

	/**
	 * Checks form and returns key/value pairs of all fields
	 * Returns NULL if form has errors
	 *
	 * @param {boolean} ignoreErrors
	 * @returns {?Object}
	 */
	getValues(ignoreErrors = false) {
		if (!ignoreErrors) {
			this.restoreFormState();
		}

		const fields = new Map(),
			values = {},
			required = [],
			validators = [];

		for (let fld of this._options.fields) {
			fields.set(fld.name, fld);
		}

		this._el.querySelectorAll('input, select, textarea').forEach(/** @param {HTMLInputElement} el */(el) => {
			const name = el.name,
				value = el.value;

			if (!fields.has(name)) {
				return;
			}

			if (el.type === 'checkbox') {
				values[name] = el.checked;
			} else if (el.type === 'radio') {
				values[name] = el.checked ? value : (values[name] || '');
			} else if (el.type === 'uint') {
				values[name] = parseInt(value);
			} else {
				values[name] = value;
			}

			if (ignoreErrors) {
				return;
			}

			/** @type {T_FormField} */
			let fld = fields.get(name);
			if (fld.required && String(value) === '') {
				required.push(name);
				return;
			}

			if (Array.isArray(fld.validators) && String(value) !== '') {
				for (let validator of fld.validators) {
					let message = validator(value);
					if (typeof message === 'string' && message !== '') {
						validators.push({name, message});
					}
				}
			}
		});

		if (ignoreErrors) {
			return values;
		}

		if (required.length > 0) {
			this.showError(i18n('errors.input.required'), required);
			return null;
		}

		if (validators.length > 0) {
			this.showError(validators[0].message, [validators[0].name]);
			return null;
		}

		return values;
	}

	/**
	 * @param {Object} data
	 * @returns {Form}
	 */
	setValues(data) {
		if (!this._el) {
			this.render();
		}

		this._el.querySelectorAll('input, select, textarea').forEach(/** @param {HTMLInputElement} el */(el) => {
			let name = el.name,
				value = el.value;

			if (!data.hasOwnProperty(name)) {
				return;
			}

			if (el.type === 'checkbox') {
				el.checked = data[name] === true;
			} else if (el.type === 'radio') {
				el.checked = String(data[name]) === value;
			} else {
				el.value = data[name];

				if (el.tagName === 'INPUT' && el.type === 'range') {
					// update value caption
					let caption = el.parentNode.querySelector('span');
					if (caption) {
						caption.textContent = el.value;
					}
				}
			}

			if (typeof el.onchange === 'function') {
				el.onchange(new CustomEvent('change'));
			}
		});

		this._disableFieldsByCallbacks();

		return this;
	}

	/**
	 * Cancels error/recommendation states
	 *
	 * @returns {Form}
	 */
	restoreFormState() {
		if (!this._els.error) {
			return this;
		}
		this._els.error.classList.remove('form__error_show');

		this._el.querySelectorAll('.form__group_error').forEach(el => {
			el.classList.remove('form__group_error');
		});

		this._el.querySelectorAll('.form__group_indicate').forEach(el => {
			el.classList.remove('form__group_indicate');
		});
		return this;
	}

	/**
	 * Shows error message and highlights the fields
	 *
	 * @param {string} message
	 * @param {Array<string>} [highlightFields]
	 * @returns {Form}
	 */
	showError(message, highlightFields = []) {
		if (!this._els.error) {
			return this;
		}

		this._els.error.classList.add('form__error_show');
		this._els.error.textContent = message;

		if (Array.isArray(highlightFields)) {
			for (let fldName of highlightFields) {
				if (this._els.groups[fldName]) {
					this._els.groups[fldName].classList.add('form__group_error');
				}
			}
		}
		return this;
	}

	/**
	 * Indicates and focuses the field, and display note message
	 *
	 * @param {string} message
	 * @param {string} fieldName
	 * @returns {Form}
	 */
	indicateField(message, fieldName) {
		if (!this._els.note) {
			return this;
		}
		this._els.note.classList.add('form__note_show');
		this._els.note.textContent = message;
		if (this._els.groups[fieldName]) {
			this._els.groups[fieldName].classList.add('form__group_indicate');
			setTimeout(() => {
				let inpEl = this._els.groups[fieldName].querySelector('input');
				if (inpEl) {
					inpEl.focus();
				}
			}, 100);
		}
		return this;
	}

	/**
	 * @returns {Form}
	 */
	render() {
		let oldElem = this._el;
		this._el = template.createElement({
			message: this._options.message || '',
			modifier: this._options.modifier,
			fields: this._options.fields
		});
		this._els.note = this._el.querySelector('.form__note');
		this._els.error = this._el.querySelector('.form__error');
		this._el.querySelectorAll('.form__group').forEach((el) => {
			this._els.groups[el.getAttribute('data-name')] = el;
		});

		this._el.querySelectorAll('input, select, textarea').forEach((el) => {
			this._disableFieldsByCallbacks();
			el.addEventListener('change', () => {
				this._events.trigger(EVENT_FIELD_CHANGE, {
					name: el.name,
					value: el.value
				});
			});

			el.addEventListener('input', () => {
				this._disableFieldsByCallbacks();
				this._events.trigger(EVENT_FIELD_CHANGE, {
					name: el.name,
					value: el.value
				});
			});
		})

		this._el.querySelectorAll('.form__range span').forEach((el) => {
			let range = el.parentNode.querySelector('input[type="range"]');
			range.addEventListener('change', () => {
				el.textContent = range.value;
			});
			range.addEventListener('input', () => {
				el.textContent = range.value;
			});
			el.textContent = range.value;
		});

		this._el.addEventListener('submit', (e) => {
			e.preventDefault();
			if (typeof this._options.onApply === 'function') {
				let data = this.getValues();
				if (data !== null) {
					this._options.onApply(data);
				}
			}
		});

		if (oldElem && oldElem.parentNode) {
			oldElem.parentNode.replaceChild(this._el, oldElem);
		}

		this._disableFieldsByCallbacks();

		return this;
	}

	/**
	 * @param {string} fieldName
	 * @param {boolean} flag
	 * @private
	 */
	_disableField(fieldName, flag) {
		/** @type {HTMLElement} */
		const groupEl = this._els.groups[fieldName];
		if (groupEl.hasAttribute('disabled') === flag) {
			return;
		}

		if (flag) {
			groupEl.setAttribute('disabled', 'disabled');
		} else {
			groupEl.removeAttribute('disabled');
		}

		groupEl.querySelectorAll('input, textarea, select').forEach(el => {
			el.disabled = flag;
		});
	}

	/**
	 * @private
	 */
	_disableFieldsByCallbacks() {
		const values = this.getValues(true);
		for (const fld of this._options.fields) {
			if (typeof fld.disableIf !== 'function') {
				continue;
			}
			this._disableField(fld.name, fld.disableIf(values));
		}
	}
}
