import {SCENE_FPS, SCENE_FRAMES} from "ui/config";
import MatrixViewSize from "ui/front/matrix/MatrixViewSize";
import VideoScenePreloader from "ui/front/matrix/VideoScenePreloader";

/**
 * @typedef {Object} T_VideoSceneOptions
 * @property {string} id
 * @property {{mp4: string, webm: string}} video
 * @property {string} preview
 * @property {number} x
 * @property {number} y
 * @property {number} zoomFactor
 */

export default class VideoScene {
	/**
	 * @param {T_VideoSceneOptions} options
	 */
	constructor(options) {
		/**
		 * @type {HTMLElement}
		 * @private
		 */
		this._el = document.createElement('DIV');
		this._el.className = 'video-scene';

		/**
		 * @type {T_VideoSceneOptions}
		 * @private
		 */
		this._options = options;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._loadStarted = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._loaded = false;

		/**
		 * @type {number}
		 * @private
		 */
		this._loadedValue = 0;

		/**
		 * @type {number[]}
		 * @private
		 */
		this._lastFramesSeekingTime = [];

		/**
		 * @type {HTMLVideoElement}
		 * @private
		 */
		this._video = null;

		/**
		 * @type {HTMLVideoElement}
		 * @private
		 */
		this._videoCandidate = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isError = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isVideoShown = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isFrameLoaded = true;

		/**
		 * @type {function():void}
		 * @private
		 */
		this._frameLoaderResolver = null;

		/**
		 * @type {number}
		 * @private
		 */
		this._fallbackTimerId = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._syncFrames = 0;

		/**
		 * @type {VideoScenePreloader|null}
		 * @private
		 */
		this._preloader = null;

		this._initElement();
	}

	/**
	 * @returns {T_VideoSceneOptions}
	 */
	get options() {
		return this._options;
	}

	/**
	 * @returns {HTMLElement}
	 */
	get element() {
		return this._el;
	}

	/**
	 * @returns {boolean}
	 */
	get isLoading() {
		return !this._loaded;
	}

	/**
	 * @returns {boolean}
	 */
	get isSyncNeeded() {
		return !this._loaded || this._syncFrames > 0;
	}

	/**
	 * @return {number}
	 */
	get currentTime() {
		if (!this._video) {
			return -1;
		}

		return this._video.currentTime;
	}

	startSyncFramesPeriod() {
		this._syncFrames = 30;
	}

	stop() {
		if (!this._loaded) {
			return;
		}

		if (this._video && !this._video.paused) {
			this._video.pause();
		}
	}

	/**
	 * @param {number} frameTime
	 * @param {boolean} [sync=false]
	 * @returns {Promise<void>}
	 */
	async loadFrame(frameTime, sync = false) {
		if (this._isError) {
			return;
		}

		if (!this._isFrameLoaded) {
			return;
		}

		if (!this._video) {
			if (!this._loadStarted) {
				this._loadVideo();
			}

			return;
		}

		this._isFrameLoaded = false;

		await new Promise(resolve => {
			const s = Date.now();

			if (this._syncFrames > 0) {
				this._syncFrames--;
			}

			this._video.onseeked = null;

			this._frameLoaderResolver = () => {
				this._frameLoaderResolver = null;
				resolve();
			};

			if (this._loaded && !sync) {
				if (this._video.paused) {
					this._video.play().then();
				}

				this._isFrameLoaded = true;
				this._frameLoaderResolver();
				return;
			}

			this._video.onseeked = () => {
				this._isFrameLoaded = true;

				if (!this._loaded) {
					this._lastFramesSeekingTime.push(Date.now() - s);
					this._updateState();
				}

				if (this._frameLoaderResolver) {
					this._frameLoaderResolver();
				}
			};

			if (!this._video.paused) {
				this._video.pause();
			}

			this._video.currentTime = frameTime;

			if (!this._isVideoShown) {
				this._frameLoaderResolver();
			}
		});
	}

	/**
	 * @param {number} factor
	 * @return {VideoScene}
	 */
	changeZoomFactor(factor) {
		this._options.zoomFactor = factor;
		this._resizeElements();
		return this;
	}

	/**
	 * @private
	 */
	_initElement() {
		if (this._options.preview) {
			this._el.style.setProperty('--bg-image', `url('${this._options.preview}')`);
		}
		this._resizeElements();
	}

	/**
	 * @private
	 */
	_buildPreloader() {
		if (this._preloader) {
			return;
		}

		this._destroyVideo();
		this._preloader = new VideoScenePreloader();
		this._resizeElements();
		this._el.appendChild(this._preloader.canvas);
	}

	/**
	 * @private
	 */
	_resizeElements() {
		const {videoWidth, videoHeight} = MatrixViewSize.getInstance().getSizeForZoomFactor(this._options.zoomFactor);

		if (this._preloader) {
			this._preloader.setSize(videoWidth, videoHeight);
		}

		this._el.style.width = `${videoWidth}px`;
		this._el.style.height = `${videoHeight}px`;
	}

	/**
	 * @private
	 */
	_destroyPreloader() {
		if (!this._preloader) {
			return;
		}

		this._preloader.destroy();
		this._preloader = null;
	}

	/**
	 * @private
	 */
	_destroyVideo() {
		this._isVideoShown = false;

		if (this._videoCandidate) {
			this._videoCandidate.onerror = null;
			this._videoCandidate.onprogress = null;
			this._videoCandidate.onloadedmetadata = null;
			this._videoCandidate = null;
		}

		if (this._video) {
			this._video.remove();
			this._video = null;
		}
	}

	/**
	 * @private
	 */
	_updateState() {
		if (this._isError) {
			return;
		}

		if (this._loaded) {
			return;
		}

		let frameTime = Number.MAX_VALUE;

		if (this._lastFramesSeekingTime.length > 0) {
			frameTime = this._lastFramesSeekingTime.reduce((acc, v) => acc + v, 0)
				/ this._lastFramesSeekingTime.length;
		}

		if (this._video
			&& (
				this._lastFramesSeekingTime.length > 60
				|| (this._lastFramesSeekingTime.length > 20 && frameTime < 100)
				|| this._loadedValue > 0.95
			)) {
			this._loaded = true;
			this.startSyncFramesPeriod();
			this._showVideo();
		} else {
			this._preloader.setValue(Math.max(this._loadedValue, this._lastFramesSeekingTime.length / SCENE_FRAMES));
		}
	}

	/**
	 * @private
	 */
	_showVideo() {
		if (this._isError) {
			return;
		}

		this._destroyPreloader();

		this._isVideoShown = true;
		this._el.appendChild(this._video);
		try {
			this._video.play().then();
		} catch (e) {
			console.error(e.message);
		}
	}

	/**
	 * @private
	 */
	_loadVideo() {
		this._unsetErrorState();
		this._destroyVideo();
		this._setFallbackTimer();
		this._buildPreloader();

		this._loadStarted = true;
		this._loadedValue = 0;

		const video = document.createElement('video');
		this._videoCandidate = video;

		video.muted = true;
		video.autoplay = true;
		video.loop = true;
		video.setAttribute('pip', 'false');
		video.setAttribute('playsinline', 'true');
		video.playsinline = true;

		this._preloader.setValue(0);

		video.onerror = () => {
			this._setErrorState();
		};

		video.onprogress = () => {
			this._unsetFallbackTimer();
			let buffered = video.buffered;
			if (buffered.length === 0) {
				return;
			}

			let time = 0;
			for (let i = 0; i < buffered.length; i++) {
				time += buffered.end(i) - buffered.start(i);
			}

			const value = time / video.duration;
			this._loadedValue = 0.05 + (0.95 * value);
			this._updateState();
		};

		video.onloadedmetadata = () => {
			this._unsetFallbackTimer();
			video.pause();
			this._video = video;
			this._preloader.setValue(0.05);
		};

		const src = document.createElement('source');
		src.type = 'video/mp4';
		src.onerror = () => {
			this._setErrorState();
		};

		src.src = this._options.video.mp4;

		video.appendChild(src);
	}

	/**
	 * @private
	 */
	_setFallbackTimer() {
		this._unsetFallbackTimer();
		this._fallbackTimerId = setTimeout(this._loadVideo.bind(this), 2000);
	}

	/**
	 * @private
	 */
	_unsetFallbackTimer() {
		clearTimeout(this._fallbackTimerId);
	}

	/**
	 * @private
	 */
	_setErrorState() {
		this._destroyVideo();
		this._destroyPreloader();

		if (this._options.video.mp4.indexOf('cdn.floor796') !== -1) {
			console.log('fallback - try to load video from origin');
			this._options.video.mp4 = this._options.video.mp4.replace(/https:\/\/cdn\.floor796\.com/, '');

			if (this._frameLoaderResolver) {
				this._frameLoaderResolver();
			}
			this._loadVideo.bind(this);
			return;
		}

		this._isError = true;
		this._el.classList.add('video-scene_error');

		if (this._frameLoaderResolver) {
			this._frameLoaderResolver();
		}

		this._setFallbackTimer();
	}

	/**
	 * @private
	 */
	_unsetErrorState() {
		if (!this._isError) {
			return;
		}

		this._isError = false;
		this._el.classList.remove('video-scene_error');
	}
}
