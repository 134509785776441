/**
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Object}
 */
export function mergeObjects(obj1, obj2) {
	const out = Object.assign({}, obj1);
	for (const key of Object.keys(obj2)) {
		if (out.hasOwnProperty(key)) {
			if (isObject(out[key]) && isObject(obj2[key])) {
				out[key] = mergeObjects(out[key], obj2[key])
			} else {
				out[key] = obj2[key];
			}
		}
	}
	return out;
}

function isObject(obj) {
	return typeof obj === 'object'
		&& obj !== undefined
		&& obj !== null
		&& obj.constructor === Object;
}
