import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import Form from "ui/elements/form/Form";
import Utils from "ui/helpers/Utils";
import Spinner from "ui/components/spinner/Spinner";
import Ajax from "ui/helpers/Ajax";
import FatalError from "ui/components/fatal-error/FatalError";
import {validateEmail} from "ui/helpers/validators";

export default class UnsubsDialog extends Dialog {
	constructor(email, token) {
		super({
			title: i18n('title.unsubscribe'),
			smallWidth: true,
			buttons: [
				{
					caption: i18n('buttons.unsubscribe'),
					role: 'unsubscribe',
					icon: '#icon-svg-close'
				},
				{
					caption: i18n('buttons.cancel'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {Form}
		 * @private
		 */
		this._form = null;

		/**
		 * @type {?function(result:boolean|null)}
		 * @private
		 */
		this._onComplete = null;

		void this._buildForm(email, token);
	}

	/**
	 * @returns {Promise<boolean|null>}
	 */
	getResultPromise() {
		return new Promise((resolve) => {
			this._onComplete = resolve;
		});
	}

	/**
	 * @override
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		super._handleButton(role);
		if (role === 'unsubscribe') {
			let data = this._form.getValues();
			if (data !== null) {
				this._apply(data).then();
			}
		}
	}

	/**
	 * @private
	 * @param {Object} data
	 * @returns {Promise<void>}
	 */
	async _apply(data) {
		Spinner.show();

		let res;
		try {
			res = await Ajax.post(
				`/subs/unsubscribe-by-token/${encodeURIComponent(data.token)}/${encodeURIComponent(data.email)}`,
				{}
			);

			if (res.result !== 'ok') {
				this._form.showError(res.error, ['email']);
				Spinner.hide();
				return;
			}
		} catch (e) {
			FatalError.show(typeof e === 'string' ? e : i18n('errors.http.error'));
			Spinner.hide();
			return;
		}

		if (this._onComplete) {
			this._onComplete(true);
			this._onComplete = null;
			this.destroy();
		}
	}

	/**
	 * @private
	 * @param {string} [email]
	 * @param {string} [token]
	 * @returns {Promise<void>}
	 */
	async _buildForm(email, token) {
		let el = document.createElement('div');
		this._form = new Form({
			parent: el,
			message: i18n('hints.unsubs'),
			onApply: this._apply.bind(this),
			values: {email, token},
			fields: [
				{
					type: 'hidden',
					name: 'token',
				},
				{
					type: 'text',
					name: 'email',
					readonly: true,
					caption: i18n('inputs.user.email'),
					required: true,
					validators: [validateEmail],
					maxlength: 100
				}
			]
		});
		this.setContent(el);
		// focus first input
		await Utils.wait(40);
		let inp = el.querySelector('input');
		inp.focus();
		inp.select();
	}

	destroy() {
		if (this._onComplete !== null) {
			this._onComplete(null);
			this._onComplete = null;
		}
		super.destroy();
	}
}
