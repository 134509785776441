import {CLIENT_ID_LS_KEY, SESSION_TTL, UPDATE_SESSION_TIMEOUT, VISIT_NUMBER_LS_KEY, VISIT_TIME_LS_KEY} from "ui/config";
import {generateUuid, isUuid} from "ui/helpers/uuid";

export default class ClientSession {
	constructor() {
		/**
		 * @type {string}
		 * @private
		 */
		this._uuid = '';

		/**
		 * @type {number}
		 * @private
		 */
		this._visitNumber = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._visitTime = this._getSessionTime();

		this.update();

		setInterval(this._updateLocalStorage.bind(this), UPDATE_SESSION_TIMEOUT);
	}

	/**
	 * @return {string}
	 */
	get uuid() {
		return this._uuid;
	}

	/**
	 * @return {number}
	 */
	get visitNumber() {
		return this._visitNumber;
	}

	update() {
		this._uuid = this._readClientId();
		this._visitNumber = this._readVisitNumber();
		const time = this._readVisitTime();

		if (this._uuid === '' || this._visitTime !== time || this._visitNumber === 0) {
			this._uuid = generateUuid();
			this._visitNumber++;
			this._updateLocalStorage();
		}
	}

	/**
	 * @private
	 * @return {string}
	 */
	_readClientId() {
		const id = window.localStorage.getItem(CLIENT_ID_LS_KEY);
		return !id || !isUuid(id) ? '' : id;
	}

	/**
	 * @private
	 * @return {number}
	 */
	_readVisitNumber() {
		const num = parseInt(window.localStorage.getItem(VISIT_NUMBER_LS_KEY) || '0', 10);
		return isNaN(num) || num <= 0 || num > 100_000_000 ? 0 : num;
	}

	/**
	 * @private
	 * @return {number}
	 */
	_readVisitTime() {
		const time = parseInt(window.localStorage.getItem(VISIT_TIME_LS_KEY) || '0', 10);
		return isNaN(time) || time < 0 ? 0 : time;
	}

	/**
	 * @private
	 */
	_updateLocalStorage() {
		if (this._readVisitNumber() > this._visitNumber) {
			return;
		}

		this._visitTime = this._getSessionTime();
		window.localStorage.setItem(VISIT_NUMBER_LS_KEY, String(this._visitNumber));
		window.localStorage.setItem(VISIT_TIME_LS_KEY, String(this._visitTime));
		window.localStorage.setItem(CLIENT_ID_LS_KEY, this._uuid);
	}

	/**
	 * @return {number}
	 * @private
	 */
	_getSessionTime() {
		return Math.ceil(Date.now() / SESSION_TTL);
	}
}
