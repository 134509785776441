import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import Form from "ui/elements/form/Form";
import Utils from "ui/helpers/Utils";
import Spinner from "ui/components/spinner/Spinner";
import Ajax from "ui/helpers/Ajax";
import FatalError from "ui/components/fatal-error/FatalError";

/**
 * @typedef {Object} T_EmailCodeOptions
 * @property {string} url
 * @property {object} payload
 */

export default class EmailCode extends Dialog {
	/**
	 * @param {T_EmailCodeOptions} options
	 */
	constructor(options = {}) {
		super({
			title: i18n('title.check-email-addr'),
			buttons: [
				{
					caption: i18n('buttons.apply'),
					role: 'apply',
					icon: '/images/icons.svg#icon-svg-check'
				},
				{
					caption: i18n('buttons.close'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {T_EmailCodeOptions}
		 * @private
		 */
		this._options = options;

		/**
		 * @type {Form}
		 * @private
		 */
		this._form = null;

		/**
		 * @type {?function(data:string)}
		 * @private
		 */
		this._onComplete = null;

		void this._buildForm();
	}

	/**
	 * @returns {Promise<string>}
	 */
	getResultPromise() {
		return new Promise((resolve) => {
			this._onComplete = resolve;
		});
	}

	/**
	 * Handles click by button
	 *
	 * @override
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		super._handleButton(role);
		if (role === 'apply') {
			let data = this._form.getValues();
			if (data !== null) {
				this._apply(data).then();
			}
		}
	}

	/**
	 * @private
	 * @param {Object} data
	 * @returns {Promise<void>}
	 */
	async _apply(data) {
		if (this._options.layer) {
			this._options.layer.updateProps(data);
		}

		Spinner.show();

		let res;
		try {
			res = await Ajax.post(this._options.url, {
				...this._options.payload,
				code: data.code,
			});

			if (res.result !== 'ok') {
				this._form.showError(res.error, ['code']);
				Spinner.hide();
				return;
			}

			data.token = res.token;
		} catch (e) {
			FatalError.show(typeof e === 'string' ? e : i18n('errors.http.error'));
			Spinner.hide();
			return;
		}

		if (this._onComplete) {
			this._onComplete(data.code);
			this._onComplete = null;
			this.destroy();
		}
	}

	/**
	 * @private
	 * @returns {Promise<void>}
	 */
	async _buildForm() {
		let el = document.createElement('div');
		this._form = new Form({
			parent: el,
			onApply: this._apply.bind(this),
			fields: [
				{
					type: 'text',
					name: 'code',
					caption: i18n('inputs.release.code'),
					required: true,
					maxlength: 20
				}
			]
		});
		this.setContent(el);
		this._form.indicateField(i18n('messages.email-code', this._options.payload.email || 'email'), 'code')
		// focus first input
		await Utils.wait(40);
		let inp = el.querySelector('input');
		inp.focus();
		inp.select();
	}

	destroy() {
		if (this._onComplete !== null) {
			this._onComplete('');
			this._onComplete = null;
		}
		super.destroy();
	}
}
