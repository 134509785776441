import {getRectForPoints} from "ui/helpers/points";
import {IS_HIGH_DPR, STATIC_URL} from "ui/config";

export default class CanvasViewAddon {
	/**
	 * @param {string} points
	 * @param {MatrixViewPosition} pos
	 * @param {string} url
	 * @param {CanvasViewSlot[]} slots
	 * @param {string} condition
	 */
	constructor(points, pos, url, slots, condition) {
		/**
		 * @type {MatrixViewPosition}
		 * @private
		 */
		this._pos = pos;

		/**
		 * @type {CanvasViewSlot[]}
		 * @private
		 */
		this._slots = slots;

		/**
		 * @type {CanvasViewSlot[]}
		 * @private
		 */
		this._overlappedSlots = [];

		/**
		 * @type {string}
		 * @private
		 */
		this._points = points;

		/**
		 * @type {string}
		 * @private
		 */
		this._condition = condition;

		/**
		 * @type {number}
		 * @private
		 */
		this._prevZoomFactor = -1;

		/**
		 * @type {{top: number, left: number, bottom: number, width: number, right: number, height: number}|null}
		 * @private
		 */
		this._rect = null;

		/**
		 * @type {number}
		 * @private
		 */
		this._stage = 0;

		/**
		 * @type {string}
		 * @private
		 */
		this._url = url;

		/**
		 * @type {null|function(ctx:CanvasRenderingContext2D, frame:number)}
		 * @private
		 */
		this._renderCallback = null;
	}

	/**
	 * @return {boolean}
	 */
	isVisible() {
		if (this._rect === null || this._prevZoomFactor !== this._pos.zoomFactor) {
			this._updateViewRect();
		}

		let {x, y, width, height} = this._pos;
		const right = x + width;
		const bottom = y + height;

		return this._rect.left < right
			&& this._rect.right > x
			&& this._rect.top < bottom
			&& this._rect.bottom > y
			&& this._checkCondition();
	}

	/**
	 * @return {boolean}
	 */
	isLoaded() {
		return this._stage > 0;
	}

	async load() {
		if (this._stage > 0) {
			return;
		}

		this._stage = 1;

		const jsCode = await (await fetch(this._url, {cache: 'force-cache'})).text();
		this._renderCallback = (new Function(jsCode + '\n; return render || null;'))();

		if (typeof this._renderCallback === 'function') {
			this._stage = 2;
		}
	}

	unload() {
		// do nothing
	}

	/**
	 * @param {CanvasRenderingContext2D} renderCtx
	 * @param {number} frame
	 */
	render(renderCtx, frame) {
		if (this._stage < 2) {
			return;
		}

		if (this._overlappedSlots.some(slot => slot.isVisible() && !slot.isRenderPossible())) {
			return;
		}

		let {x, y} = this._pos;
		const scale = IS_HIGH_DPR ? 2 : 1;

		renderCtx.save();
		renderCtx.translate((this._rect.left - x) * scale, (this._rect.top - y) * scale);

		try {
			this._renderCallback(renderCtx, frame, this._pos.zoomFactor, IS_HIGH_DPR, STATIC_URL, this._rect);
		} catch (e) {
			console.error(e);
		}
		renderCtx.restore();
	}

	/**
	 * @private
	 */
	_updateViewRect() {
		const {x, y} = this._pos;
		this._prevZoomFactor = this._pos.zoomFactor;
		const pts = this._points.split(';')
			.map(point => this._pos.parsePositionCode(point))
			.filter(point => point !== null)
			.map(point => ({x: x + point.x, y: y + point.y}));

		this._rect = getRectForPoints(pts);

		if (this._overlappedSlots.length === 0) {
			for (const slot of this._slots) {
				if (slot.isOverlapRect(this._rect.left, this._rect.top, this._rect.width, this._rect.height)) {
					this._overlappedSlots.push(slot);
				}
			}
		}
	}

	/**
	 * @private
	 */
	_checkCondition() {
		if (!this._condition) {
			return true;
		}

		for (const cond of this._condition.split(';')) {
			const [cmd, value] = cond.split(':', 2);
			switch (cmd) {
				case 'lsb': {
					// check Byte value in LocalStorage Key
					const props = value.split(',');
					const lsValue = window.localStorage.getItem(props[0]);

					if (!lsValue || lsValue.substr(parseInt(props[1], 10), 1) !== props[2]) {
						return false;
					}
					break;
				}
			}
		}

		return true;
	}
}
