import {CLIENT_WS_CONNECTION_DELAY} from "ui/config";

export default class ClientActivity {
	constructor() {
		/**
		 * @type {boolean}
		 * @private
		 */
		this._isActive = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._waiting = true;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._wasPointerTouchEvent = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._wasPointerMoveEvent = false;

		/**
		 * @type {Promise<void>|null}
		 * @private
		 */
		this._activationPromise = null;

		/**
		 * @type {function|null}
		 * @private
		 */
		this._activationPromiseResolver = null;

		window.setTimeout(() => {
			this._waiting = false;
			this._update();
		}, CLIENT_WS_CONNECTION_DELAY);

		const pointerTouchHandler = this._handlePointerTouchEvent.bind(this);
		document.addEventListener('mousedown', pointerTouchHandler, {once: true, capture: true});
		document.addEventListener('touchstart', pointerTouchHandler, {once: true, capture: true});

		const pointerMoveHandler = this._handlePointerMoveEvent.bind(this);
		document.addEventListener('mousemove', pointerMoveHandler, {once: true, capture: true});
		document.addEventListener('touchmove', pointerMoveHandler, {once: true, capture: true});

		document.addEventListener('visibilitychange', this._update.bind(this));
	}

	get isActive() {
		return this._isActive;
	}

	getActivationPromise() {
		if (this._activationPromise) {
			return this._activationPromise;
		}

		if (this._isActive) {
			return Promise.resolve();
		}

		this._activationPromise = new Promise(r => this._activationPromiseResolver = r);

		return this._activationPromise;
	}

	/**
	 * @private
	 */
	_handlePointerTouchEvent() {
		this._wasPointerTouchEvent = true;
		this._update();
	}

	/**
	 * @private
	 */
	_handlePointerMoveEvent() {
		this._wasPointerMoveEvent = true;
		this._update();
	}

	/**
	 * @private
	 */
	_update() {
		if (this._isActive) {
			return;
		}

		// active if:
		// 1. tab is active
		// 2. there was at least one click/tap
		// 3. left 15 sec and user moved cursor
		this._isActive = !document.hidden
			&& (this._wasPointerTouchEvent || (this._waiting && this._wasPointerMoveEvent));

		if (this._isActive && this._activationPromiseResolver !== null) {
			this._activationPromiseResolver();
		}
	}
}
