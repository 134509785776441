import MatrixViewPosition from "ui/front/matrix/MatrixViewPosition";
import {getCenterForPoints, getRectForPoints} from "ui/helpers/points";
import MatrixViewSize from "ui/front/matrix/MatrixViewSize";
import {IS_WEBP_SUPPORTED, STATIC_URL} from "ui/config";

export default class ChangesImageLoader {
	/**
	 * @param {T_UpdateOnFrontPage[]} changes
	 * @param {MatrixLoader} matrixLoader
	 * @param {number} [width]
	 * @param {number} [height]
	 */
	constructor(changes, matrixLoader, width = 200, height = 200) {
		if (!matrixLoader.data) {
			throw new Error('Matrix data was not loaded');
		}

		/**
		 * @type {number}
		 * @private
		 */
		this._width = width;

		/**
		 * @type {number}
		 * @private
		 */
		this._height = height;

		/**
		 * @type {T_UpdateOnFrontPage[]}
		 * @private
		 */
		this._changes = changes;

		/**
		 * @type {T_MatrixData}
		 * @private
		 */
		this._matrix = matrixLoader.data;

		/**
		 * @type {MatrixViewPosition}
		 * @private
		 */
		this._matrixPos = new MatrixViewPosition(
			document.createElement('div'),
			1,
			matrixLoader.sceneIds
		);

		/**
		 * @type {HTMLCanvasElement}
		 * @private
		 */
		this._canvas = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isLowQuality = false;

		/**
		 * @type {{x: number, width: number, y: number, height: number}}
		 * @private
		 */
		this._itemRect = {x: 0, y: 0, width: 0, height: 0};

		/**
		 * @type {number}
		 * @private
		 */
		this._extraScale = 0;
	}

	/**
	 * @param {boolean} flag
	 */
	setLowQuality(flag) {
		this._isLowQuality = flag;
	}

	setExtraScaleValue(value) {
		this._extraScale = value;
	}

	/**
	 * @return {{x: number, width: number, y: number, height: number}}
	 */
	get itemRectInsideImage() {
		return this._itemRect;
	}

	/**
	 * @param {string} id
	 * @return {Promise<HTMLCanvasElement|null>}
	 */
	async getImage(id) {
		const item = this._changes.find(item => item.id === id);
		if (!item) {
			return null;
		}

		const pts = item.p.split(';')
			.map(p => this._matrixPos.parsePositionCode(p))
			.filter(point => point !== null);

		const rect = getRectForPoints(pts);
		const centerPt = getCenterForPoints(pts);
		let scale = 1;

		if (rect.width > this._width || rect.height > this._height) {
			scale = Math.max(rect.width / this._width, rect.height / this._height);
		}

		scale += this._extraScale;

		const areaWidth = Math.ceil(this._width * scale);
		const areaHeight = Math.ceil(this._height * scale);
		const areaLeft = centerPt.x - (areaWidth >> 1);
		const areaTop = centerPt.y - (areaHeight >> 1);

		this._itemRect.x = (rect.left - areaLeft) / scale;
		this._itemRect.y = (rect.top - areaTop) / scale;
		this._itemRect.width = rect.width / scale;
		this._itemRect.height = rect.height / scale;

		const {videoWidth, videoHeight} = MatrixViewSize.getInstance().getSizeForZoomFactor(1);

		const mat = this._matrix.mat;
		const matCol = Math.floor(areaLeft / videoWidth);
		const matRow = Math.floor(areaTop / videoHeight);
		const matWidth = 1 + (areaLeft + areaWidth > videoWidth ? 1 : 0);
		const matHeight = 1 + (areaTop + areaHeight > videoHeight ? 1 : 0);
		const toLoad = [];

		for (let r = matRow; r < matRow + matHeight; r++) {
			for (let c = matCol; c < matCol + matWidth; c++) {
				toLoad.push(
					new Promise(resolve => {
						if (!mat[r][c]) {
							resolve(null);
							return;
						}

						const img = new Image();
						let imgFile = this._isLowQuality ? mat[r][c].preview : mat[r][c].preview_q;
						imgFile = IS_WEBP_SUPPORTED ? imgFile : imgFile.replace(/\.webp$/, '.jpg');
						img.src = STATIC_URL + '/data/' + imgFile;
						img.onerror = () => resolve(null);
						img.onabort = () => resolve(null);
						img.onload = () => resolve(img);
					})
				);
			}
		}

		/**
		 * @type {(HTMLImageElement|null)[]}
		 */
		const images = await Promise.all(toLoad);

		const canvas = this._getCanvas();
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		const scaledVideoWidth = videoWidth / scale;
		const scaledVideoHeight = videoHeight / scale;

		for (let r = matRow; r < matRow + matHeight; r++) {
			for (let c = matCol; c < matCol + matWidth; c++) {
				const img = images.shift();
				if (!img) {
					continue;
				}

				ctx.drawImage(
					img,
					0,
					0,
					videoWidth,
					videoHeight,
					(areaLeft - (c * videoWidth)) / -scale,
					(areaTop - (r * videoHeight)) / -scale,
					scaledVideoWidth,
					scaledVideoHeight,
				);
			}
		}

		return this._canvas;
	}

	/**
	 * @return {HTMLCanvasElement}
	 * @private
	 */
	_getCanvas() {
		if (this._canvas) {
			return this._canvas;
		}

		this._canvas = document.createElement('canvas');
		this._canvas.width = this._width;
		this._canvas.height = this._height;

		return this._canvas;
	}
}
