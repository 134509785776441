/**
 * @type {Array}
 */
let crcTable = null;

/**
 * Misc utilities and helpers
 */
export default class Utils {
	/**
	 * Creates canvas element
	 *
	 * @param {string} className
	 * @param {?number} [width]
	 * @param {?number} [height]
	 * @param {string} [fillColor]
	 * @returns {T_CanvasElement}
	 */
	static createCanvas(className, width = null, height = null, fillColor = '') {
		// noinspection JSValidateTypes
		/** @type {HTMLCanvasElement} */
		let c = document.createElement('canvas');
		className !== '' && (c.className = className);
		if (width !== null) {
			c.width = +width;
		}
		if (height !== null) {
			c.height = +height;
		}
		let ctx = c.getContext('2d');
		if (fillColor !== '') {
			ctx.fillStyle = fillColor;
			ctx.fillRect(0, 0, width, height);
		}
		return {
			el: c,
			ctx: ctx
		};
	}

	/**
	 * Loads image by URL (or data URL)
	 *
	 * @param {string} url
	 * @returns {Promise<HTMLImageElement>}
	 */
	static loadImage(url) {
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.crossOrigin = 'anonymous';
			img.addEventListener('load', () => {
				resolve(img);
			});
			img.addEventListener('error', () => {
				reject();
			});
			img.addEventListener('abort', () => {
				reject();
			});
			img.src = url;
		});
	}

	/**
	 * Changes image size
	 *
	 * @param {HTMLImageElement} img
	 * @param {number} toWidth
	 * @param {?number} [toHeight]
	 * @returns {Promise<HTMLImageElement>}
	 */
	static resizeImage(img, toWidth, toHeight = null) {
		return new Promise((resolve) => {
			if (toHeight === null) {
				toHeight = (img.naturalHeight / img.naturalWidth) * toWidth;
			}
			let c = Utils.createCanvas('', toWidth, toHeight);
			c.ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, toWidth, toHeight);
			let newImg = new Image();
			newImg.crossOrigin = 'anonymous';
			newImg.addEventListener('load', () => {
				resolve(newImg);
			});
			newImg.src = c.el.toDataURL('image/png');
		});
	}

	/**
	 * Waits the specified time
	 *
	 * @param {number} time
	 * @returns {Promise<void>}
	 */
	static wait(time) {
		return new Promise((resolve) => {
			setTimeout(resolve, time);
		});
	}

	/**
	 * Calculates CRC32 hash for string
	 *
	 * @param {string} str
	 * @returns {string}
	 */
	static getHash(str) {
		if (crcTable === null) {
			let c;
			crcTable = [];
			for (let n = 0; n < 256; n++) {
				c = n;
				for (let k = 0; k < 8; k++){
					c = ((c & 1) !== 0 ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
				}
				crcTable[n] = c;
			}
		}

		let crc = 0 ^ (-1);
		for (let i = 0, l = str.length; i < l; i++ ) {
			crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
		}
		return str.length + '@' + Math.abs((crc ^ (-1)) >>> 0);
	}
}
