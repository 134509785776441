import {mergeObjects} from "ui/helpers/objects";
import {formatHtmlFromMarkdown} from "ui/helpers/formatters";

export const enUS = require('../i18n/en-US.json');
export const enRU = require('../i18n/ru-RU.json');

export const LANG_RU = 'ru';
export const LANG_EN = 'en';
export const LANG_DEFAULT = LANG_EN;

let userLang = '';

/**
 * @return {string}
 * @private
 */
export function detectUserLanguage() {
	if (userLang !== '') {
		return userLang;
	}

	let lang;
	try {
		lang = window.localStorage.getItem('lang');
	} catch (e) {
		lang = '';
	}

	if ([LANG_EN, LANG_RU].includes(lang)) {
		userLang = lang;
		return userLang;
	}

	const langList = navigator.languages || [navigator.language];
	for (const lang of langList) {
		if (/^ru(-|$)/.test(lang)) {
			userLang = LANG_RU;
			break;
		}
	}

	return userLang || LANG_DEFAULT;
}

export function switchUserLanguage(lang) {
	window.localStorage.setItem('lang', lang);
}

export function getCountryNameByCode(code) {
	if (window.Intl) {
		try {
			return (new Intl.DisplayNames(userLang, {type: 'region'}).of(code.toUpperCase())) || code;
		} catch (e) {
			return code;
		}
	}

	return code;
}

userLang = detectUserLanguage();

let dict = enUS;
if (userLang === LANG_RU) {
	dict = mergeObjects(enUS, enRU);
}

/**
 * Returns text by the specified code
 *
 * @param {string} code
 * @param {...*} args
 * @returns {string}
 */
export const i18n = (code, ...args) => {
	let node = dict;
	for (let i of code.split('.')) {
		if (typeof node[i] !== 'undefined') {
			node = node[i];
		} else {
			return code;
		}
	}

	if (typeof node === 'object') {
		if (Array.isArray(node)) {
			node = node.join('\n');
		} else {
			node = '';
		}
	}

	node = formatHtmlFromMarkdown(node);

	return node.replace(/%[ds]/g, () => {
		return args.shift();
	});
};
