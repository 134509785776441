export default function (value1, operator, value2, options) {
	switch (operator) {
		case '===':
		case '==':
			return (value1 === value2) ? options.fn(this) : options.inverse(this);
		case '!==':
		case '!=':
			return (value1 !== value2) ? options.fn(this) : options.inverse(this);
		case '<':
			return (value1 < value2) ? options.fn(this) : options.inverse(this);
		case '<=':
			return (value1 <= value2) ? options.fn(this) : options.inverse(this);
		case '>':
			return (value1 > value2) ? options.fn(this) : options.inverse(this);
		case '>=':
			return (value1 >= value2) ? options.fn(this) : options.inverse(this);
		case '&&':
			return (value1 && value2) ? options.fn(this) : options.inverse(this);
		case '||':
			return (value1 || value2) ? options.fn(this) : options.inverse(this);
		default:
			return options.inverse(this);
	}
}
