var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list-group "
    + alias2(__default(require("../../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"highlightRecords") : depth0),"highlightRecords",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":70}}}))
    + "\">\n	<div class=\"list-group__head\">\n		<div class=\"list-item list-item_head\">\n		</div>\n	</div>\n	<div class=\"list-group__no-recs\" hidden>\n		<div>"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"messages.no-records",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":7},"end":{"line":7,"column":37}}}))
    + "</div>\n	</div>\n	<div class=\"list-group__preloader\">\n		<div>"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.loading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":7},"end":{"line":10,"column":31}}}))
    + "</div>\n	</div>\n</div>\n";
},"useData":true});