var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"dialog__head\">\n				<span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":19}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCloseButton") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "			</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "				<a href=\"javascript: void(0);\" class=\"dialog__close-button\">\n					<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"22\" height=\"22\" viewBox=\"0 0 640 640\" class=\"svg-icon\">\n						<path fill=\"currentColor\" d=\"M133.42 66.97L574.52 487.83L499.59 566.37L58.49 145.51L133.42 66.97Z\"/>\n						<path fill=\"currentColor\" d=\"M499.59 66.97L58.49 487.83L133.42 566.37L574.52 145.51L499.59 66.97Z\"/>\n					</svg>\n				</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"dialog__buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":30,"column":13}}})) != null ? stack1 : "")
    + "			</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<span class=\"button\" data-role=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"role") || (depth0 != null ? lookupProperty(depth0,"role") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"role","hash":{},"data":data,"loc":{"start":{"line":24,"column":37},"end":{"line":24,"column":45}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "						<span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"caption") || (depth0 != null ? lookupProperty(depth0,"caption") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"caption","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":23}}}) : helper)))
    + "</span>\n					</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon\"><use href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":26,"column":75},"end":{"line":26,"column":83}}}) : helper)))
    + "\"/></svg>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog "
    + alias2(__default(require("../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"smallWidth") : depth0),"dialog_small",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":56}}}))
    + " "
    + alias2(__default(require("../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"autoHeight") : depth0),"dialog_auto-height",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":100}}}))
    + " "
    + alias2(__default(require("../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showButtons") : depth0),"dialog_has-buttons",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":2,"column":36}}}))
    + " "
    + alias2(__default(require("../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showTitle") : depth0),"dialog_has-title",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":77}}}))
    + " "
    + alias2(__default(require("../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAlert") : depth0),"dialog_alert",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":78},"end":{"line":2,"column":112}}}))
    + "\"\n	style=\"--width: "
    + alias2(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":26}}}) : helper)))
    + "px\">\n	<div class=\"dialog__box\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "		<div class=\"dialog__body\">\n			<div class=\"dialog__no-body\">"
    + alias2(__default(require("../../handlebars-helpers/i18n.js")).call(alias1,"messages.dialog-loading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":19,"column":66}}}))
    + "</div>\n		</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showButtons") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":32,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});