var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a class=\"button\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"backURL") || (depth0 != null ? lookupProperty(depth0,"backURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"backURL","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":38}}}) : helper)))
    + "\" data-role=\"close\">"
    + alias2(__default(require("../../handlebars-helpers/i18n.js")).call(alias1,"buttons.back",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":81}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<button type=\"button\" data-role=\"close\">"
    + container.escapeExpression(__default(require("../../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"buttons.close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":67}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fatal-error\">\n	<div class=\"fatal-error__body\">\n		<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon fatal-error__icon\">\n			<use href=\"/images/icons.svg#icon-svg-warning\" />\n		</svg>\n		"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":15}}}) : helper))) != null ? stack1 : "")
    + "\n		<div class=\"fatal-error__buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"backURL") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":12,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"useData":true});