import {F796_SLOT_RENDER_WORKER_URL, IS_HIGH_DPR} from "ui/config";
import VideoScenePreloader from "ui/front/matrix/VideoScenePreloader";
import CanvasViewSlotPathClipper from "ui/front/matrix/CanvasViewSlotPathClipper";

const downloadSpeed = [];

/**
 * @typedef {object} T_CanvasViewSlotOptions
 * @property {string} [point]
 * @property {number} [offsetX]
 * @property {number} [offsetY]
 * @property {number} [x]
 * @property {number} [y]
 * @property {number} width
 * @property {number} height
 * @property {string} url
 * @property {boolean} [showPreloader=true]
 * @property {HTMLDivElement} [debugDiv]
 * @property {number} [duration] Number of frames to play. If set than playback will start from the first frame
 * @property {string} [activateByEventName] Event name, which is triggered on the document object, for activation
 * this slot render
 * @property {string[]} [clipPathPoints]
 * @property {function()|null} [onPlayStartCallback]
 * @property {(number|string)[]} [framesSequence] List of the frame indices and commands to play.
 * Supported list of commands:
 *  - 'goto:index'. Example: `1,2,3,4,goto:1`. This example will play 1 and 2 frames only once, then will loop 3 and 4.
 * If this property is set then duration will be ignored.
 * @property {function(frame:number, CanvasImageSource):CanvasImageSource} [prepareRenderSource]
 */

export default class CanvasViewSlot {
	/**
	 * @param {MatrixViewPosition} pos
	 * @param {T_CanvasViewSlotOptions} options
	 */
	constructor(pos, options) {
		/**
		 * @type {MatrixViewPosition}
		 * @private
		 */
		this._pos = pos;

		/**
		 * @type {T_CanvasViewSlotOptions}
		 * @private
		 */
		this._options = Object.assign({
			x: 0,
			y: 0,
			duration: 0,
			activateByEventName: '',
			framesSequence: [],
			onPlayStartCallback: null,
			showPreloader: true
		}, options);

		/**
		 * @type {ImageBitmap}
		 * @private
		 */
		this._img = null;

		/**
		 * @type {number}
		 * @private
		 */
		this._stage = 0;

		/**
		 * @type {Worker}
		 * @private
		 */
		this._worker = null;

		/**
		 * @type {function}
		 * @private
		 */
		this._currentPromiseResolve = null;

		/**
		 * @type {VideoScenePreloader}
		 * @private
		 */
		this._preloader = new VideoScenePreloader(true);

		/**
		 * @type {ImageBitmap}
		 * @private
		 */
		this._firstFrameSprite = null;

		/**
		 * @type {number}
		 * @private
		 */
		this._internalFrameNo = 0;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._activated = true;

		/**
		 * @type {CanvasViewSlotPathClipper|null}
		 * @private
		 */
		this._clipper = this._options.clipPathPoints
			? new CanvasViewSlotPathClipper(this._options.clipPathPoints, this._pos)
			: null;

		if ('point' in this._options) {
			const point = this._pos.parsePositionCode(this._options.point, false, false);
			if (point !== null) {
				this._options.x = point.x;
				this._options.y = point.y;
			}
		}

		this._options.x += this._options.offsetX || 0;
		this._options.y += this._options.offsetY || 0;

		if (this._options.activateByEventName !== '') {
			this._activated = false;

			document.addEventListener(this._options.activateByEventName, () => {
				if (this._activated) {
					return;
				}

				this._activated = true;
				this._internalFrameNo = 0;
			});
		}
	}

	static getDownloadTime() {
		return {
			max: Math.round(Math.max(...downloadSpeed, 0)),
			avg: Math.round(
				downloadSpeed.length === 0
					? 0
					: downloadSpeed.reduce((acc, v) => acc + v, 0) / downloadSpeed.length
			)
		};
	}

	isVisible() {
		const x = (this._options.x * this._pos.zoomFactor) - this._pos.x;
		const y = (this._options.y * this._pos.zoomFactor) - this._pos.y;
		return x < this._pos.width
			&& (x + this._options.width * this._pos.zoomFactor) > 0
			&& y < this._pos.height
			&& (y + this._options.height * this._pos.zoomFactor) > 0;
	}

	isOverlapRect(x, y, width, height) {
		const left = this._options.x * this._pos.zoomFactor;
		const top = this._options.y * this._pos.zoomFactor;
		const right = left + this._options.width * this._pos.zoomFactor;
		const bottom = top + this._options.height * this._pos.zoomFactor;

		return x < right
			&& x + width > left
			&& y < bottom
			&& y + height > top;
	}

	isLoaded() {
		return this._stage > 0;
	}

	isRenderPossible() {
		return this._stage > 0 && this._img !== null;
	}

	/**
	 * @return {string}
	 */
	getUrl() {
		return this._options.url || '';
	}

	unload() {
		if (this._options.activateByEventName !== '') {
			this._activated = false;
		}

		this._stage = 0;
		this._updateDebugDiv(`unloaded`);

		if (this._currentPromiseResolve !== null) {
			this._currentPromiseResolve();
			this._currentPromiseResolve = null;
		}

		this._img = null;
		if (this._worker) {
			this._worker.terminate();
			this._worker = null;
		}

		document.dispatchEvent(new CustomEvent('unload-slot', {detail: this}));
	}

	async load() {
		if (this._stage !== 0) {
			return;
		}

		this._stage = 1;

		if (!this._options.url) {
			return;
		}

		const startTime = Date.now();
		await this._initWorker();
		if (this._stage === 0) {
			return;
		}

		this._stage = 2;
		const loadTime = Date.now() - startTime;

		this._updateDebugDiv(`load time ${loadTime}`);
	}

	/**
	 * @param {number} frame
	 * @return {Promise<void>}
	 */
	async prepare(frame) {
		if (this._stage !== 2 || !this._activated) {
			return;
		}

		if (this._options.framesSequence.length > 0) {
			let value = this._options.framesSequence[this._internalFrameNo];
			if (typeof value === 'number') {
				frame = value;
				this._internalFrameNo = (this._internalFrameNo + 1) % this._options.framesSequence.length;
			} else if (typeof value === 'string') {
				const [cmd, v] = value.split(':');
				switch (cmd) {
					case 'goto':
						this._internalFrameNo = parseInt(v, 10);
						value = this._options.framesSequence[this._internalFrameNo];
						if (typeof value === 'number') {
							frame = value;
						} else {
							// invalid case
							frame = 0;
						}
						break;
					default:
						// invalid case
						frame = 0;
				}
			} else {
				// invalid case
				frame = 0;
			}
		} else if (this._options.duration > 0) {
			frame = this._internalFrameNo;

			if (++this._internalFrameNo > this._options.duration) {
				this._activated = false;
				return;
			}
		} else {
			this._internalFrameNo = frame;
		}

		const imageData = await this._decode(frame);
		if (imageData instanceof ImageData) {
			this._img = await createImageBitmap(imageData)
			if (this._firstFrameSprite !== null) {
				this._firstFrameSprite = null;
			}
		} else {
			this._img = null;
		}
	}

	/**
	 * @param {CanvasRenderingContext2D} renderCtx
	 */
	render(renderCtx) {
		if (!this._activated) {
			return;
		}

		if (!this._img) {
			if (this._options.showPreloader) {
				this._renderPreloader(renderCtx);
			}
			return;
		}

		const scale = IS_HIGH_DPR ? 2 : 1;
		let renderSource = this._img;

		renderCtx.save();

		if (this._options.prepareRenderSource) {
			// there is some foreign image processor, which can change image before render
			renderSource = this._options.prepareRenderSource(this._internalFrameNo, renderSource);
		}

		this._clipper && this._clipper.clipContext(renderCtx, scale);

		renderCtx.drawImage(
			renderSource,
			Math.ceil(((this._options.x * this._pos.zoomFactor) - this._pos.x) * scale),
			Math.ceil(((this._options.y * this._pos.zoomFactor) - this._pos.y) * scale),
			Math.ceil(this._options.width * this._pos.zoomFactor * scale),
			Math.ceil(this._options.height * this._pos.zoomFactor * scale),
		);

		renderCtx.restore();

		if (this._options.onPlayStartCallback) {
			try {
				this._options.onPlayStartCallback();
			} catch (e) {
				console.error(e);
			}
			this._options.onPlayStartCallback = null;
		}
	}

	/**
	 * @param {CanvasRenderingContext2D} displayCtx
	 * @private
	 */
	_renderPreloader(displayCtx) {
		const scale = IS_HIGH_DPR ? 2 : 1;

		if (this._firstFrameSprite) {
			displayCtx.globalAlpha = 0.2;
			displayCtx.drawImage(
				this._firstFrameSprite,
				((this._options.x * this._pos.zoomFactor) - this._pos.x) * scale,
				((this._options.y * this._pos.zoomFactor) - this._pos.y) * scale,
				this._options.width * this._pos.zoomFactor * scale,
				this._options.height * this._pos.zoomFactor * scale,
			);
			displayCtx.globalAlpha = 1;
		}

		this._preloader.renderToArea(
			displayCtx,
			((this._options.x * this._pos.zoomFactor) - this._pos.x) * scale,
			((this._options.y * this._pos.zoomFactor) - this._pos.y) * scale,
			this._options.width * this._pos.zoomFactor * scale,
			this._options.height * this._pos.zoomFactor * scale,
		);
	}

	/**
	 * @return {Promise<void>}
	 * @private
	 */
	async _initWorker() {
		await new Promise(resolve => {
			this._worker = new Worker(F796_SLOT_RENDER_WORKER_URL);
			this._worker.onmessage = (e) => {
				if (e.data === 'ready') {
					resolve();
				}
			}
		});

		await new Promise((resolve) => {
			this._currentPromiseResolve = resolve;
			this._worker.onmessage = (e) => {
				if (e.data === true) {
					this._currentPromiseResolve = null;
					this._worker.onmessage = null;
					resolve();
				} else if (e.data instanceof Object && 'load_duration' in e.data) {
					if (e.data.load_duration > 0) {
						//console.log('Download time', e.data.load_duration);
						downloadSpeed.push(e.data.load_duration);
					}
				} else if (e.data instanceof ArrayBuffer) {
					const imgData = new ImageData(
						new Uint8ClampedArray(e.data),
						this._options.width,
						this._options.height
					);

					createImageBitmap(imgData).then((img) => this._firstFrameSprite = img);
				} else if (typeof e.data === 'number') {
					this._preloader.setValue(e.data);
				}
			};
			this._worker.postMessage(['init', this._options.url, this._options.width, this._options.height]);
		});
	}

	/**
	 * @param {number} frame
	 * @return {Promise<ImageData>}
	 * @private
	 */
	_decode(frame) {
		return new Promise((resolve) => {
			this._currentPromiseResolve = resolve;
			this._worker.onmessage = (e) => {
				this._currentPromiseResolve = null;
				this._worker.onmessage = null;
				resolve(new ImageData(new Uint8ClampedArray(e.data), this._options.width, this._options.height));
			};
			this._worker.postMessage(['decode', frame]);
		});
	}

	/**
	 * @param {string} text
	 * @private
	 */
	_updateDebugDiv(text) {
		if (!this._options.debugDiv) {
			return;
		}

		this._options.debugDiv.textContent = `${text}`;
		if (this._stage === 2 && !this._options.debugDiv.style.backgroundImage) {
			this._options.debugDiv.style.backgroundImage = `url(${this._options.debugDiv.dataset.preview})`;
		}
	}
}
