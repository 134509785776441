import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import tpl from "./low-battery.handlebars";
import Template from "ui/Template";
import {MIN_ALLOWED_BATTERY_LEVEL} from "ui/config";

export default class LowBatteryDialog extends Dialog {
	constructor() {
		super({
			title: i18n('title.low-battery'),
			smallWidth: true,
			content: new Template(tpl).createHtml({
				batteryLevel: MIN_ALLOWED_BATTERY_LEVEL * 100
			}),
			buttons: [
				{
					caption: i18n('buttons.continue'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {function}
		 * @private
		 */
		this._resolve = null;
	}

	confirm() {
		return new Promise(r => {
			this._resolve = r;
		})
	}

	destroy() {
		this._resolve();
		super.destroy();
	}
}
