import PageElement from "ui/PageElement";
import EventDispatcher from "ui/EventDispatcher";
import MatrixViewPosition from "ui/front/matrix/MatrixViewPosition";
import DragController from "ui/helpers/DragController";
import VideoScenePreloader from "ui/front/matrix/VideoScenePreloader";
import ZoomController from "ui/helpers/ZoomController";
import {IS_SMALL_VIEW, ZOOM_FACTORS, ZOOM_FACTORS_EXTENDED, ZOOM_STEP} from "ui/config";
import Ajax from "ui/helpers/Ajax";
import Spinner from "ui/components/spinner/Spinner";
import {i18n} from "ui/i18n";
import FatalError from "ui/components/fatal-error/FatalError";
import MatrixLoader from "ui/front/matrix/MatrixLoader";
import CacheCleaner from "ui/front/matrix/CacheCleaner";

export const EVENT_CHANGE_ZOOM_FACTOR = 'EVENT_CHANGE_ZOOM_FACTOR';
export const EVENT_PICK_ITEM = 'EVENT_PICK_ITEM';
export const EVENT_CANCEL_ITEM = 'EVENT_CANCEL_ITEM';

/**
 * @typedef {T_PageElementOptions} T_AbstractMatrixViewOptions
 * @property {boolean} [restorePositionFromUrl=false]
 * @property {boolean} [restorePositionFromLS=false]
 * @property {boolean} [useQualityPreview=false]
 * @property {number} [zoomFactor=1]
 * @property {function(data:T_MatrixData):void} [onMatrixLoaded]
 * @property {function(frame:number):void} [onFrame]
 */

export default class AbstractMatrixView extends PageElement {
	/**
	 * @param {T_AbstractMatrixViewOptions} options
	 */
	constructor(options) {
		super(options);

		/**
		 * @type {T_AbstractMatrixViewOptions}
		 * @protected
		 */
		this._options = options;

		this._element = document.createElement('div');
		this._element.className = 'scenes-matrix';

		/**
		 * @type {number}
		 * @protected
		 */
		this._frame = 0;

		/**
		 * @type {EventDispatcher}
		 * @protected
		 */
		this._events = new EventDispatcher();

		/**
		 * @type {string[][]}
		 * @private
		 */
		this._sceneIdsMatrix = [];

		/**
		 * @type {MatrixViewPosition}
		 * @protected
		 */
		this._matrixPosition = new MatrixViewPosition(this._element, this._options.zoomFactor || 1, this._sceneIdsMatrix);

		/**
		 * @type {T_MatrixData|null}
		 * @protected
		 */
		this._matrixData = null;

		/**
		 * @type {boolean}
		 * @protected
		 */
		this._stopped = false;

		/**
		 * @type {number}
		 * @private
		 */
		this._zoomAnimationRequestId = 0;

		this._element.oncontextmenu =
		this._element.onauxclick = (e) => {
			e.preventDefault();
			this.events.trigger(EVENT_CANCEL_ITEM);
		};

		try {
			VideoScenePreloader.prepareSprites();
		} catch (e) {
			console.error(e);
		}

		this.activatePageElement();
	}

	/**
	 * @return {MatrixViewPosition}
	 */
	get position() {
		return this._matrixPosition;
	}

	/**
	 * @return {EventDispatcher}
	 */
	get events() {
		return this._events;
	}

	/**
	 * @return {?Element}
	 */
	get element() {
		return this._element;
	}

	/**
	 * @return {number}
	 */
	get currentFrame() {
		return this._frame;
	}

	/**
	 * @return {Object}
	 */
	get matrixData() {
		return this._matrixData;
	}

	play() {
		this._stopped = false;
	}

	stop() {
		this._stopped = true;
	}

	enableDraggingTool() {
		let moved = false,
			pickX, pickY,
			left, top;

		const ctrl = new DragController({
			element: this._element,
			start: (x, y) => {
				pickX = x;
				pickY = y;
				left = this._matrixPosition.x;
				top = this._matrixPosition.y;
				moved = false;
			},
			move: (dx, dy) => {
				this._matrixPosition.setPosition(left - dx, top - dy);

				if (!moved && (Math.abs(dx) > 15 || Math.abs(dy) > 15)) {
					moved = true;
					this._events.trigger(EVENT_CANCEL_ITEM);
				}
			},
			finish: () => {
				if (this._options.restorePositionFromUrl) {
					this._matrixPosition.savePosition();
				}

				if (!moved && !ctrl.isMultipleTouchesDetected) {
					this._events.trigger(EVENT_PICK_ITEM, {x: pickX, y: pickY});
				}

				this._syncNextFrame = true;
			}
		});
	}

	enableZoomFactorSwitcher() {
		new ZoomController({
			target: this._element,
			stepSize: ZOOM_STEP,
			callback: this.changeZoomFactorByDelta.bind(this)
		});
	}

	/**
	 * @param {number} delta
	 * @param {boolean} isWheel
	 * @param {number} x
	 * @param {number} y
	 * @param {boolean} isCtrl
	 */
	changeZoomFactorByDelta(delta, isWheel, x, y, isCtrl) {
		const shift = delta < 0 ? -1 : 1;
		const factors = screen.availWidth > 2100 || (isWheel && isCtrl)
			? ZOOM_FACTORS_EXTENDED
			: ZOOM_FACTORS;

		let index = factors.indexOf(this._options.zoomFactor || 1);
		if (index < 0) {
			// find nearest value in array
			index = factors.map((v, i) => [
				Math.abs(v - this._options.zoomFactor),
				i
			]).sort((v1, v2) => v1[0] - v2[0])[0][1];
		}


		if (this._zoomAnimationRequestId > 0) {
			cancelAnimationFrame(this._zoomAnimationRequestId);
			this._zoomAnimationRequestId = 0;
		}

		const fromZoom = this._options.zoomFactor;
		const toZoom = factors[Math.max(0, Math.min(factors.length - 1, index + shift))];
		if (fromZoom === toZoom) {
			return;
		}

		const steps = 5;
		const zoomStep = (toZoom - fromZoom) / steps;
		let value = 1;

		const animateZoom = () => {
			this.changeZoomFactor(fromZoom + zoomStep * value, x, y);
			if (++value <= steps) {
				this._zoomAnimationRequestId = requestAnimationFrame(animateZoom);
			} else {
				this._zoomAnimationRequestId = 0;
			}
		}

		this._zoomAnimationRequestId = requestAnimationFrame(animateZoom);
	}

	/**
	 * @param {number} factor
	 * @param {number} x
	 * @param {number} y
	 */
	changeZoomFactor(factor, x, y) {
		if (this._options.zoomFactor === factor) {
			return;
		}

		const centerCode = this._matrixPosition.savePosition();

		this._options.zoomFactor = factor;

		this._matrixPosition.changeZoomFactor(centerCode, factor, x, y);

		if (ZOOM_FACTORS_EXTENDED.includes(factor) || ZOOM_FACTORS.includes(factor)) {
			this._matrixPosition.savePosition();
			this._events.trigger(EVENT_CHANGE_ZOOM_FACTOR);
		}
	}

	/**
	 * @return {Promise<void>}
	 */
	async build() {
		this._matrixData = await this._loadMatrix();

		if (this._matrixData) {
			// remove missing/unused files from the browser cache based on the loaded matrix info
			await (new CacheCleaner(this._matrixData)).clear();
		}
	}

	/**
	 * @return {Promise<T_MatrixData|null>}
	 * @private
	 */
	async _loadMatrix() {
		Spinner.show();

		const loader = new MatrixLoader();

		if (!(await loader.load()) || !loader.data) {
			FatalError.show(i18n('errors.http.error'));
			Spinner.hide();
			return null;
		}

		const data = loader.data;
		this._sceneIdsMatrix.push(...loader.sceneIds);

		Spinner.hide();

		this._options.onMatrixLoaded && this._options.onMatrixLoaded(data);

		return data;
	}
}
