import {IS_HIGH_DPR} from "ui/config";

const PRELOADER_X_STEP = 180;
const PRELOADER_Y_STEP = 120;
const SCALE = IS_HIGH_DPR ? 2 : 1;
const PI_2 = Math.PI * 2;

/**
 * @type {null|HTMLImageElement}
 */
let sprites = null;

export default class VideoScenePreloader {
	/**
	 * @param {boolean} useForeignCanvas
	 */
	constructor(useForeignCanvas = false) {
		/**
		 * @type {number}
		 * @private
		 */
		this._value = 0;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._foreignCanvas = useForeignCanvas;

		/**
		 * @type {HTMLCanvasElement|null}
		 * @private
		 */
		this._canvas = useForeignCanvas ? null : document.createElement('canvas');

		/**
		 * @type {CanvasRenderingContext2D|null}
		 * @private
		 */
		this._context = this._canvas ? this._canvas.getContext('2d') : null;
	}

	static prepareSprites() {
		const canvas = document.createElement('canvas');
		canvas.width = Math.ceil(10 * PRELOADER_X_STEP * SCALE);
		canvas.height = Math.ceil(10 * PRELOADER_Y_STEP * SCALE);

		const ctx = canvas.getContext('2d');
		const dx = 33 * SCALE;
		const dy = 26 * SCALE;
		const dx2 = dx << 1;
		const dy2 = dy << 1;
		const textIndent = 15 * SCALE;
		const lineWidth = 3 * SCALE;
		const dotRadius = 4 * SCALE;
		const color1 = '#9faab3';
		const color2 = '#c3c9cf';

		ctx.lineWidth = lineWidth;
		ctx.lineCap = 'round';
		ctx.font = (11 * SCALE) + 'px Tahoma, Helvetica, Arial, Verdana'

		let x, y;

		for (let value = 0; value <= 1; value += 0.01) {
			const v = Math.round(value * 100);
			x = (PRELOADER_X_STEP * (v % 10) + (PRELOADER_X_STEP >> 1)) * SCALE;
			y = ((PRELOADER_Y_STEP * Math.floor(v / 10)) + (PRELOADER_Y_STEP >> 1)) * SCALE;
			const percent = v + '%';

			ctx.beginPath();
			ctx.globalAlpha = 0.5;
			ctx.strokeStyle = color1;
			ctx.moveTo(x - dx, y + dy);
			ctx.lineTo(x + dx, y - dy);
			ctx.stroke();
			ctx.globalAlpha = 1;

			ctx.beginPath();
			ctx.save();
			ctx.shadowColor = color2;
			ctx.shadowBlur = 5 * SCALE;
			ctx.strokeStyle = color2;
			ctx.moveTo(x - dx, y + dy);
			ctx.lineTo(x - dx + (dx2 * value), y + dy - (dy2 * value));
			ctx.stroke();
			ctx.restore();

			ctx.fillStyle = color2;
			ctx.fillText(percent, x - dx + (dx2 * value) + textIndent, y + dy - (dy2 * value) + textIndent);

			ctx.beginPath();
			ctx.save();
			ctx.fillStyle = color2;
			ctx.shadowColor = color2;
			ctx.shadowBlur = 10 * SCALE;
			ctx.arc(x - dx + (dx2 * value), y + dy - (dy2 * value), dotRadius, 0, PI_2);
			ctx.fill();
			ctx.restore();
		}

		sprites = new Image();
		sprites.src = canvas.toDataURL('image/png');
	}

	/**
	 * @return {HTMLCanvasElement}
	 */
	get canvas() {
		return this._canvas;
	}

	/**
	 * @param {number} value
	 */
	setValue(value) {
		this._value = Math.min(1, Math.max(0, +value));
		if (!this._foreignCanvas) {
			this._renderToSelfCanvas();
		}
	}

	setSize(width, height) {
		if (this._foreignCanvas) {
			return;
		}

		this._canvas.width = width * SCALE;
		this._canvas.height = height * SCALE;
		this._canvas.style.width = width + 'px';
		this._canvas.style.height = height + 'px';
		this._renderToSelfCanvas();
	}

	destroy() {
		if (this._foreignCanvas) {
			return;
		}

		if (this._canvas.parentNode) {
			this._canvas.remove();
		}
	}

	renderToArea(ctx, offsetX, offsetY, width, height) {
		if (!sprites || !sprites.complete) {
			return;
		}

		const xStep = PRELOADER_X_STEP * SCALE;
		const yStep = PRELOADER_Y_STEP * SCALE;
		const preloaderCols = Math.floor(width / xStep);
		const preloaderRows = Math.floor(height / yStep);
		const preloaderXStep = width / preloaderCols;
		const preloaderYStep = height / preloaderRows;

		const v = Math.ceil(this._value * 100);
		const spriteX = (v % 10) * xStep;
		const spriteY = Math.floor(v / 10) * yStep;

		for (let r = 0; r < preloaderRows; r++) {
			for (let c = 0; c < preloaderCols; c++) {
				let x = Math.round(c * preloaderXStep);
				let y = Math.round(r * preloaderYStep);
				ctx.drawImage(
					sprites,
					spriteX, spriteY, xStep, yStep,
					offsetX + x, offsetY + y, xStep, yStep
				);
			}
		}
	}

	_renderToSelfCanvas() {
		const ctx = this._context;
		const sceneWidth = this._canvas.width;
		const sceneHeight = this._canvas.height;
		ctx.clearRect(0, 0, sceneWidth, sceneHeight);
		this.renderToArea(ctx, 0, 0, sceneWidth, sceneHeight);
	}
}
