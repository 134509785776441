var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"change-record__icon\" data-icon=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":4,"column":46},"end":{"line":4,"column":54}}}) : helper)))
    + "\">\n			<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon\">\n				<use href=\"#icon-svg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":6,"column":25},"end":{"line":6,"column":33}}}) : helper)))
    + "\"></use>\n			</svg>\n		</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"change-record "
    + alias2(__default(require("../../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isNew") : depth0),"change-record_new",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":63}}}))
    + " "
    + alias2(__default(require("../../../handlebars-helpers/ifClass.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isInteractive") : depth0),"change-record_interactive",{"name":"ifClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":117}}}))
    + "\">\n	<div class=\"change-record__group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":9,"column":8}}})) != null ? stack1 : "")
    + "		<div class=\"change-record__content\">\n			<div class=\"change-record__head\">\n				<span class=\"change-record__new-indicator\">&bull;</span>\n				<span class=\"change-record__date\" title=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"list.create",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":45},"end":{"line":13,"column":67}}}))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":13,"column":69},"end":{"line":13,"column":77}}}) : helper)))
    + "</span>\n				<span class=\"change-record__author\" title=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"list.authors",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":70}}}))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"author") || (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"author","hash":{},"data":data,"loc":{"start":{"line":14,"column":72},"end":{"line":14,"column":82}}}) : helper)))
    + "</span>\n			</div>\n			<div class=\"change-record__body\">\n				<div class=\"change-record__title\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":47}}}) : helper)))
    + "</div>\n				<span class=\"change-record__int-label\" translate=\"no\">interactive</span>\n				<a class=\"change-record__button\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":51}}}) : helper)))
    + "\" target=\"_blank\">\n					<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon\">\n						<use href=\"#icon-svg-path\"></use>\n					</svg>\n				</a>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});