import Template from "ui/Template";
import {
	SCENE_FINAL_HEIGHT,
	SCENE_FINAL_WIDTH,
	SCENE_FPS,
	SCENE_FRAMES,
	SCENE_GRID_INDENT,
	STATIC_URL,
} from "ui/config";
import VideoScene from "ui/front/matrix/VideoScene";
import scenesView from './scenes.handlebars';
import MatrixViewSize from "ui/front/matrix/MatrixViewSize";
import AbstractMatrixView from "ui/front/matrix/AbstractMatrixView";

const ONE_FRAME_DURATION = 1 / SCENE_FPS;

export default class VideoMatrixView extends AbstractMatrixView {
	/**
	 * @param {T_AbstractMatrixViewOptions} options
	 */
	constructor(options) {
		super(options);

		/**
		 * @type {Set<VideoScene>}
		 * @private
		 */
		this._videosInViewport = new Set();

		/**
		 * @type {Array<Array<VideoScene>>}
		 * @private
		 */
		this._matrix = [];

		/**
		 * @type {boolean}
		 * @private
		 */
		this._syncNextFrame = false;

		/**
		 * @type {number}
		 * @private
		 */
		this._syncAfterFrames = 0;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._visibilityCheckerInited = false;

		this._render = {
			timer: 0,
			func: this._renderNextFrame.bind(this),
			timeout: ((SCENE_FRAMES / SCENE_FPS) / SCENE_FRAMES) * 1000,
			isStopped: false,
		};

		this._matrixPosition.addObserver((x, y) => {
			this._element.scrollLeft = x;
			this._element.scrollTop = y;
			this._syncNextFrame = true;
		});
	}

	/**
	 * @return {Promise<void>}
	 */
	async build() {
		await super.build();
		this._buildMatrix();

		this._element.appendChild(
			new Template(scenesView)
				.createElement({
					mat: this._matrix,
					columns: this._matrix[0].length,
					...this._getSizes()
				})
		);

		this._matrixPosition.init(
			this._options.restorePositionFromUrl,
			this._options.restorePositionFromLS,
		);

		const observer = new IntersectionObserver(async (intersections) => {
			for (const intersection of intersections) {
				const contEl = intersection.target;
				const video = this._matrix[+contEl.dataset.row][+contEl.dataset.col];

				if (!intersection.isIntersecting) {
					if (video && this._videosInViewport.has(video)) {
						this._videosInViewport.delete(video);
						video.stop();
					}
				} else if (video) {
					this._videosInViewport.add(video);

					if (contEl.childElementCount === 0) {
						contEl.appendChild(video.element);
					}
				}
			}
		});

		this._element.querySelectorAll('.scenes-matrix__cell').forEach(el => {
			observer.observe(el);
		});
	}

	play() {
		super.play();
		this._renderNextFrame().then();
		this._initDocumentVisibilityChecker();
	}

	stop() {
		super.stop();
		this._videosInViewport.forEach(video => video.stop());
		clearTimeout(this._render.timer);
	}

	/**
	 * @param {number} factor
	 * @param {number} x
	 * @param {number} y
	 */
	changeZoomFactor(factor, x, y) {
		if (this._options.zoomFactor === factor) {
			return;
		}

		super.changeZoomFactor(factor, x, y);

		const mainEl = this._element.querySelector('.scenes-matrix__scenes');
		if (mainEl) {
			const sizes = this._getSizes();
			mainEl.style.setProperty('--width', `${sizes.width}px`);
			mainEl.style.setProperty('--height', `${sizes.height}px`);
			mainEl.style.setProperty('--indent', `${sizes.indent}px`);
		}

		for (const row of this._matrix) {
			for (const scene of row) {
				scene.changeZoomFactor(factor);
			}
		}

		this._syncNextFrame = true;
	}

	/**
	 * @private
	 */
	_initDocumentVisibilityChecker() {
		if (this._visibilityCheckerInited) {
			return;
		}

		this._visibilityCheckerInited = true;
		document.addEventListener('visibilitychange', (e) => {
			if (document.visibilityState === 'visible') {
				if (this._stopped) {
					return;
				}
				this._syncNextFrame = true;
				[...this._videosInViewport].forEach(video => video.startSyncFramesPeriod());
				this.play();
			}
		});
	}

	/**
	 * @return {{indent: number, width: number, height: number}}
	 * @private
	 */
	_getSizes() {
		const factor = this._options.zoomFactor || 1;
		const {wallHeight} = MatrixViewSize.getInstance().getSizeForZoomFactor(factor);
		return {
			indent: SCENE_GRID_INDENT * factor + 1,
			width: SCENE_FINAL_WIDTH * factor,
			height: SCENE_FINAL_HEIGHT * factor - wallHeight + 1
		};
	}

	/**
	 * @private
	 */
	_buildMatrix() {
		const {mat} = this._matrixData;

		for (let r = 0; r < mat.length; r++) {
			const row = [];
			for (let c = 0; c < mat[r].length; c++) {
				const cell = mat[r][c];
				row.push(new VideoScene({
					id: cell.id,
					video: {
						mp4: STATIC_URL + (cell.video.mp4 ? '/data/' + cell.video.mp4 : ''),
						webm: STATIC_URL + (cell.video.webm ? '/data/' + cell.video.webm : ''),
					},
					preview: STATIC_URL + '/data/' + (this._options.useQualityPreview ? cell.preview_q : cell.preview),
					zoomFactor: this._options.zoomFactor || 1
				}));
			}

			this._matrix.push(row);
		}
	}

	async _renderNextFrame() {
		if (this._stopped) {
			clearTimeout(this._render.timer);
			return;
		}

		let startTime = 0;
		const videos = [...this._videosInViewport];

		clearTimeout(this._render.timer);

		if (document.hidden) {
			this._render.timer = setTimeout(this._render.func, 200);

			if (!this._render.isStopped) {
				this._render.isStopped = true;
				videos.forEach(video => video.stop());
			}
			return;
		} else {
			this._render.isStopped = false;
		}

		startTime = Date.now();

		this._frame = (this._frame + 1) % SCENE_FRAMES;
		this._options.onFrame && this._options.onFrame(this._frame);

		let hardSync = videos.some(video => video.isLoading);
		let sync = hardSync || this._syncNextFrame;

		this._syncAfterFrames--;

		if (this._frame % 10 === 0) {
			if (videos.some(video => video.isSyncNeeded)) {
				sync = true;
			}

			if (this._syncAfterFrames <= 0) {
				sync = true;
				// any try to make this number smaller fails because on slow computers it is very visible sync lag.
				// Before this number was SCENE_FRAMES * 3
				this._syncAfterFrames = 80;
			}
		}

		let frameTime;

		if (hardSync) {
			frameTime = this._frame * ONE_FRAME_DURATION;
		} else {
			frameTime = Math.max(0, ...videos.map(video => video.currentTime));
		}

		this._syncNextFrame = false;
		/*if (sync) {
			console.log(`sync videos. Time: ${frameTime}s;`);
		}*/

		await Promise.all(
			videos.map(video => video.loadFrame(frameTime, sync))
		);

		let loadTime = Date.now() - startTime;

		if (!this._stopped) {
			this._render.timer = setTimeout(this._render.func, Math.max(40, this._render.timeout - loadTime));
		}
	}
}
