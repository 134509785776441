import {
	IS_F796_RENDER_ENGINE,
	SCENE_FINAL_HEIGHT,
	SCENE_FINAL_WIDTH,
	SCENE_GRID_INDENT, SCENE_SLOT_HEIGHT, SCENE_SLOT_WIDTH,
	WALL_HEIGHT_COEFFICIENT
} from "ui/config";

/**
 * @type {MatrixViewSize}
 */
let instance = null;

/**
 * @typedef {object} T_MatrixScale
 * @property {number} videoWidth
 * @property {number} videoHeight
 * @property {number} videoIndent
 * @property {number} wallHeight
 */

export default class MatrixViewSize {
	constructor() {
		if (instance !== null) {
			return instance;
		}

		/**
		 * @type {Map<number, T_MatrixScale>}
		 * @private
		 */
		this._cache = new Map();
	}

	/**
	 * @return {MatrixViewSize}
	 */
	static getInstance() {
		if (instance === null) {
			instance = new MatrixViewSize();
		}

		return instance;
	}

	/**
	 * @param {number} factor
	 * @return {T_MatrixScale}
	 */
	getSizeForZoomFactor(factor) {
		if (this._cache.has(factor)) {
			return this._cache.get(factor);
		}

		let size;

		if (IS_F796_RENDER_ENGINE) {
			const sceneWidth = SCENE_SLOT_WIDTH * 2 * factor;
			const sceneHeight = SCENE_SLOT_HEIGHT * 2 * factor;
			size = {
				videoIndent: 0,
				wallHeight: Math.round(sceneWidth * WALL_HEIGHT_COEFFICIENT),
				videoWidth: sceneWidth,
				videoHeight: sceneHeight
			};
		} else {
			const sceneWidth = SCENE_FINAL_WIDTH * factor;
			const sceneHeight = SCENE_FINAL_HEIGHT * factor;
			const videoIndent = SCENE_GRID_INDENT * factor + 1; // 1 - it is a real magic :(
			const wallHeight = Math.round(sceneWidth * WALL_HEIGHT_COEFFICIENT);

			size = {
				videoIndent,
				wallHeight,
				videoWidth: sceneWidth - videoIndent * 2,
				videoHeight: sceneHeight - wallHeight - videoIndent * 2 + 1 // 1 - it is a magic :(
			};
		}

		this._cache.set(factor, size);

		return size;
	}
}
