import SocketClient from "ui/front/websocket/SocketClient";
import StatClient from "ui/front/websocket/StatClient";

export default class WebSocketService {
	constructor(clientSession, clientActivity) {
		this._activity = clientActivity;
		this._socket = new SocketClient(clientSession);
		this._statClient = new StatClient(this._socket);
	}

	async init() {
		this._activity.getActivationPromise().then(async () => {
			await this._socket.init();

		});
		this._statClient.init();
	}

	/**
	 * @return {SocketClient}
	 */
	get socket() {
		return this._socket;
	}

	/**
	 * @return {StatClient}
	 */
	get stat() {
		return this._statClient;
	}
}
