import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";

export default class InteractiveElementDialog extends Dialog {
	constructor(title, url) {
		const initWidth = 500;
		super({
			title,
			width: initWidth,
			autoHeight: true,
			content: `
				<div class="interactive-dialog">
					<iframe src="${url}" frameborder="0"></iframe>
					<div class="interactive-dialog__loading"><span>${i18n('title.loading')}</span></div>
				</div>
			`
		});

		const iframe = this._els.body.querySelector('iframe');
		iframe.addEventListener('custom-load', (e) => {
			let [width, height, hideScrollBar] = e.detail;
			if (this._els.body.offsetWidth < initWidth) {
				width = this._els.body.offsetWidth;
			}

			iframe.style.width = `${width}px`;
			iframe.style.height = `${height}px`;

			if (hideScrollBar) {
				iframe.setAttribute('scrolling', 'no');
				this._els.body.style.overflow = 'hidden';
			}

			this._els.body.querySelector('.interactive-dialog').classList.add('interactive-dialog_loaded');
			this._element.style.setProperty('--width', `${width}px`);
			iframe.contentWindow.focus();
		});

		/**
		 * @private
		 */
		this._onClose = () => {};
	}

	/**
	 * @returns {Promise<void>}
	 */
	getClosePromise() {
		return new Promise((resolve) => {
			this._onClose = resolve;
		});
	}

	destroy() {
		this._onClose();
		super.destroy();
	}
}
