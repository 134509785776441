import MatrixViewSize from "ui/front/matrix/MatrixViewSize";
import {LAST_POSITION_LS_KEY} from "ui/config";
import MatrixViewPositionTransition from "ui/front/matrix/MatrixViewPositionTransition";

const CENTER_POSITION = 't2r0,542,707';

export default class MatrixViewPosition {
	/**
	 * @param {Element} el
	 * @param {number} zoomFactor
	 * @param {string[][]} matrix
	 */
	constructor(el, zoomFactor, matrix) {
		/**
		 * @type {HTMLElement}
		 * @private
		 */
		this._element = el;

		/**
		 * @type {number}
		 * @private
		 */
		this._x = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._y = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._zoomFactor = zoomFactor;

		/**
		 * @type {string[][]}
		 * @private
		 */
		this._matrix = matrix;

		/**
		 * @type {Map<string,number[]>}
		 * @private
		 */
		this._matrixPosBySceneIds = new Map();

		/**
		 * @type {Map<string,string>}
		 * @private
		 */
		this._sceneIdByMatrixPos = new Map();

		/**
		 * @type {number}
		 * @private
		 */
		this._timerId = 0;

		/**
		 * @type {function(?x: number, ?y: number:void)[]}
		 * @private
		 */
		this._observers = [];

		/**
		 * @type {MatrixViewPositionTransition}
		 * @private
		 */
		this._transition = new MatrixViewPositionTransition(this);
	}

	get x() {
		return this._x;
	}

	get y() {
		return this._y;
	}

	get width() {
		return this._element.offsetWidth;
	}

	get height() {
		return this._element.offsetHeight;
	}

	get zoomFactor() {
		return this._zoomFactor;
	}

	get transition() {
		return this._transition;
	}

	/**
	 * @param {function(x: number, y: number):void} func
	 */
	addObserver(func) {
		this._observers.push(func);
	}

	/**
	 * @param {function(x: number, y: number):void} func
	 */
	removeObserver(func) {
		const i = this._observers.indexOf(func);
		if (i > -1) {
			this._observers.splice(i, 1);
		}
	}

	/**
	 * @param {number} x
	 * @param {number} y
	 */
	setPosition(x, y) {
		this._x = x;
		this._y = y;
		this._observers.forEach(func => func(this._x, this._y));
	}

	/**
	 * @param {boolean} restorePositionFromUrl
	 * @param {boolean} restorePositionFromLs
	 */
	init(restorePositionFromUrl = true, restorePositionFromLs = true) {
		if (restorePositionFromUrl && this.setPositionCode(String(window.location.hash))) {
			return;
		}

		if (restorePositionFromLs && this.setPositionCode(window.localStorage.getItem(LAST_POSITION_LS_KEY) || '')) {
			return;
		}

		this.scrollToCenter();
	}

	/**
	 * @param {string} centerCode
	 * @param {number} factor
	 * @param {number} x
	 * @param {number} y
	 * @return {MatrixViewPosition}
	 */
	changeZoomFactor(centerCode, factor, x, y) {
		const center = this.parsePositionCode(centerCode);
		if (!center) {
			return this;
		}

		const pageWidth2 = this.width >> 1;
		const pageHeight2 = this.height >> 1;
		const dx = (x - pageWidth2) / this._zoomFactor;
		const dy = (y - pageHeight2) / this._zoomFactor;

		const cx1 = (this.x + center.x) / this._zoomFactor;
		const cy1 = (this.y + center.y) / this._zoomFactor;

		const scaleBefore = this._zoomFactor;
		this._zoomFactor = factor;

		this.setPosition(
			(cx1 * factor) - pageWidth2 - (dx * scaleBefore - dx * factor),
			(cy1 * factor) - pageHeight2 - (dy * scaleBefore - dy * factor),
		);

		this.transition.updateProperties();

		return this;
	}

	/**
	 * Saves scroll position into the URL hash, returns position code
	 *
	 * @returns {string}
	 */
	savePosition() {
		const pageWidth = this._element.offsetWidth;
		const pageHeight = this._element.offsetHeight
		const code = this.getPositionCode(pageWidth >> 1, pageHeight >> 1);
		try {
			// safari triggers an error if page too often changes the history state
			window.history.replaceState({}, document.title, '#' + code);
		} catch(e) {}
		window.localStorage.setItem(LAST_POSITION_LS_KEY, code);
		return code;
	}

	/**
	 * @param {string} code
	 * @param {boolean} [applyZoomFactor=true]
	 * @param {boolean} [addViewportPosition=true]
	 * @return {null|{x: number, y: number}}
	 */
	parsePositionCode(code, applyZoomFactor = true, addViewportPosition = true) {
		const hashParams = code.match(/^#?([tb]\d+[lr]\d+),(\d+),(\d+)(,\d+)?$/);
		if (!hashParams) {
			return null;
		}

		const zoomFactor = applyZoomFactor ? this._zoomFactor : 1;

		const epsilon = 2;
		const {videoWidth, videoHeight} = MatrixViewSize.getInstance()
			.getSizeForZoomFactor(zoomFactor);

		const x = parseInt(hashParams[3]) * zoomFactor;
		const y = parseInt(hashParams[2]) * zoomFactor;

		if (x < 0 || x - videoWidth > epsilon || y < 0 || y - videoHeight > epsilon) {
			return null;
		}

		this._buildAbstractMatrix();

		const pos = this._matrixPosBySceneIds.get(hashParams[1]);
		if (!pos) {
			return null;
		}

		return {
			x: (videoWidth * pos[1] + x) - (addViewportPosition ? this._x : 0),
			y: (videoHeight * pos[0] + y) - (addViewportPosition ? this._y : 0),
		};
	}

	/**
	 * @param {number} x
	 * @param {number} y
	 * @return {string}
	 */
	getPositionCode(x, y) {
		const {videoWidth, videoHeight} = MatrixViewSize.getInstance().getSizeForZoomFactor(this._zoomFactor);

		x += this._x;
		y += this._y;
		const col = Math.floor(x / videoWidth);
		const row = Math.floor(y / videoHeight);

		this._buildAbstractMatrix();

		const code = this._sceneIdByMatrixPos.get(row + ':' + col);
		if (!code) {
			return '';
		}

		x = Math.round((x - col * videoWidth) / this._zoomFactor);
		y = Math.round((y - row * videoHeight) / this._zoomFactor);

		return code + `,${y},${x}`;
	}

	/**
	 * @param {string} code
	 * @param {boolean} asCenterOfScreen
	 * @return {boolean}
	 */
	setPositionCode(code, asCenterOfScreen = true) {
		const pos = this.parsePositionCode(code);
		if (!pos) {
			return false;
		}

		const pageWidth = this._element.offsetWidth;
		const pageHeight = this._element.offsetHeight

		this.setPosition(
			this.x + pos.x - (asCenterOfScreen ? pageWidth >> 1 : 0),
			this.y + pos.y - (asCenterOfScreen ? pageHeight >> 1 : 0)
		);
		this._transition.stop();

		return true;
	}

	scrollToCenter() {
		if (!this._matrix || !this._matrix[0]) {
			return;
		}

		this.setPositionCode(CENTER_POSITION);
	}

	/**
	 * @return {void}
	 * @private
	 */
	_buildAbstractMatrix() {
		if (this._matrixPosBySceneIds.size > 0) {
			return;
		}

		const mat = {};
		const matShift = [0, 0];

		for (let r = -10; r < 10; r++) {
			for (let c = -10; c < 10; c++) {
				let cy = r - c;
				let cx = c + r;
				let code = (cy <= 0 ? 't' + (-cy) : 'b' + cy) + (cx < 0 ? 'l' + (-cx) : 'r' + cx);
				mat[code] = [r, c];

				if (code === this._matrix[0][0]) {
					matShift[0] = r;
					matShift[1] = c;
				}
			}
		}

		for (const [code, pos] of Object.entries(mat)) {
			const r = pos[0] - matShift[0];
			const c = pos[1] - matShift[1];
			this._matrixPosBySceneIds.set(code, [r, c]);
			this._sceneIdByMatrixPos.set(r + ':' + c, code);
		}
	}
}
