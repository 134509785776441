import {getRectForPoints} from "ui/helpers/points";
import {EVENT_CHANGE_ZOOM_FACTOR} from "ui/front/matrix/AbstractMatrixView";

/**
 * @typedef {Object} T_ChangeItem
 * @property {T_UpdateOnFrontPage} item
 * @property {{x: number, y: number}[]} pts
 * @property {{x: number, y: number}} from
 * @property {{x: number, y: number}} to
 * @property {number} square
 */

export default class ChangesMap {
	/**
	 * @param {AbstractMatrixView} matrix
	 */
	constructor(matrix) {
		/**
		 * @type {AbstractMatrixView}
		 * @private
		 */
		this._matrix = matrix;

		/**
		 * @type {T_ChangeItem[]}
		 * @private
		 */
		this._items = [];

		/**
		 * @type {T_UpdateOnFrontPage[]}
		 * @private
		 */
		this._changes = [];

		/**
		 * @type {Map<number[], T_ChangeItem>}
		 * @private
		 */
		this._itemsByColors = new Map();

		/**
		 * @type {number}
		 * @private
		 */
		this._updateItemsTimer = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._prevZoomFactor = -1;

		/**
		 * @type {Set<T_UpdateOnFrontPage>}
		 * @private
		 */
		this._seenItems = new Set();

		this._canvas = document.createElement('canvas');
		this._ctx = this._canvas.getContext('2d', {willReadFrequently: true});

		if (window.location.search.indexOf('debug') > -1) {
			this._canvas.style.cssText = 'position: fixed; top: 0; left: 0; z-index:100000';
			document.body.appendChild(this._canvas);
		}

		this._matrix.position.addObserver(this._updateItemsListLazy.bind(this));
		this._matrix.events.addListener(EVENT_CHANGE_ZOOM_FACTOR, this._updateItemsListLazy.bind(this));
		window.addEventListener('resize', this._updateCanvasSize.bind(this));

		this._updateCanvasSize();
	}

	/**
	 * @return {Set<T_UpdateOnFrontPage>}
	 */
	getSeenItems() {
		return this._seenItems;
	}

	/**
	 * @param {T_UpdateOnFrontPage[]} list
	 */
	setItems(list) {
		this._changes = list;
		this._items = [];
		this._prevZoomFactor = -1;
		this._updateItemsList();
	}

	/**
	 * @param {number} x
	 * @param {number} y
	 * @returns {null|T_UpdateOnFrontPage}
	 */
	getItemAtPosition(x, y) {
		const rgba = this._ctx.getImageData(x >> 1, y >> 1, 1, 1).data;

		for (const color of this._itemsByColors.keys()) {
			const r = Math.abs(color[0] - rgba[0]);
			const g = Math.abs(color[1] - rgba[1]);
			const b = Math.abs(color[2] - rgba[2]);
			if (r < 2 && g < 2 && b < 2) {
				return this._itemsByColors.get(color).item;
			}
		}


		return null;
	}

	/**
	 * @private
	 */
	_updateCanvasSize() {
		this._canvas.width = (window.innerWidth || document.documentElement.clientWidth) >> 1;
		this._canvas.height = (window.innerHeight || document.documentElement.clientHeight) >> 1;
		this._renderItems();
	}

	/**
	 * @private
	 */
	_updateItemsListLazy() {
		clearTimeout(this._updateItemsTimer);
		this._updateItemsTimer = setTimeout(this._updateItemsList.bind(this), 50);
	}

	/**
	 * @private
	 */
	_updateItemsList() {
		if (this._prevZoomFactor === this._matrix.position.zoomFactor) {
			this._renderItems();
			return;
		}

		const {x, y} = this._matrix.position;
		this._prevZoomFactor = this._matrix.position.zoomFactor;
		this._items = this._changes
			.filter(item => item.p !== '')
			.map(item => {
				const pts = item.p.split(';')
					.map(point => this._matrix.position.parsePositionCode(point))
					.map(point => ({x: x + point.x, y: y + point.y}))
					.filter(point => point !== null);
				const {left, top, right, bottom} = getRectForPoints(pts);

				return {
					item,
					pts,
					square: (right - left) * (bottom - top),
					from: {
						x: left,
						y: top,
					},
					to: {
						x: right,
						y: bottom,
					},
				};
			});

		this._renderItems();
	}

	/**
	 * @private
	 */
	_renderItems() {
		const ctx = this._ctx;

		this._itemsByColors.clear();

		ctx.fillStyle = '#000000';
		ctx.fillRect(0, 0, this._canvas.width, this._canvas.height);

		let {x, y, width, height} = this._matrix.position;
		const right = x + width;
		const bottom = y + height;

		let color = 0x0000FF;

		for (const item of this._items) {
			if (
				item.from.x >= right
				|| item.to.x <= x
				|| item.from.y >= bottom
				|| item.to.y <= y
				) {
				continue;
			}

			if (item.pts.length < 3) {
				continue;
			}

			if (item.item.l.includes('no-click')) {
				continue;
			}

			color += 15000;
			color %= 0xFFFFFF;

			const r = (color >> 16) & 0xFF;
			const g = (color >> 8) & 0xFF;
			const b = color & 0xFF;

			ctx.fillStyle = '#' + color.toString(16).padStart(6, '0');
			ctx.beginPath();
			ctx.moveTo((item.pts[0].x - x) >> 1, (item.pts[0].y - y) >> 1);
			for (let i = 1; i < item.pts.length; i++) {
				ctx.lineTo((item.pts[i].x - x) >> 1, (item.pts[i].y - y) >> 1);
			}
			ctx.closePath();
			ctx.fill();

			this._itemsByColors.set([r, g, b], item);

			if (
				!this._seenItems.has(item.item)
				&& this._getItemVisibleSquare(item, x, y, right, bottom) / item.square > 0.5
				) {
				this._seenItems.add(item.item);
			}
		}
	}

	/**
	 * @param {T_ChangeItem} item
	 * @param {number} left
	 * @param {number} top
	 * @param {number} right
	 * @param {number} bottom
	 * @private
	 */
	_getItemVisibleSquare(item, left, top, right, bottom) {
		const w = Math.min(item.to.x, right) - Math.max(item.from.x, left);
		const h = Math.min(item.to.y, bottom) - Math.max(item.from.y, top);
		return w * h;
	}
}
