/**
 * @typedef {object} T_ZoomControllerOptions
 * @property {Element} target
 * @property {number} [stepSize=50]
 * @property {function(delta: number, isWheel: boolean, x: number, y: number, isCtrl: boolean): void} callback
 */
export default class ZoomController {
	/**
	 * @param {T_ZoomControllerOptions} options
	 */
	constructor(options) {
		/**
		 * @private
		 * @type {T_ZoomControllerOptions}
		 */
		this._options = options;

		/**
		 * @type {number}
		 * @private
		 */
		this._prevDiff = -1;

		this._initWheel();
		this._initPinchGestures();
	}

	/**
	 * @private
	 */
	_initWheel() {
		this._options.target.addEventListener('wheel', (e) => {
			e.preventDefault();
			this._options.callback(e.deltaY < 0 ? -1 : 1, true, e.clientX, e.clientY, e.ctrlKey || e.metaKey);
		});
	}

	/**
	 * @private
	 */
	_initPinchGestures() {
		const el = this._options.target;
		el.addEventListener('touchmove', this._handlePointerMove.bind(this));
	}

	/**
	 * @param {TouchEvent} e
	 * @private
	 */
	_handlePointerMove(e) {
		e.preventDefault();
		if (e.touches.length === 2) {
			const e1 = e.touches[0];
			const e2 = e.touches[1];
			const dist = Math.sqrt((e1.clientX - e2.clientX) ** 2 + (e1.clientY - e2.clientY) ** 2);
			if (this._prevDiff < 0) {
				this._prevDiff = dist;
			} else {
				const step = this._options.stepSize || 50;
				if (Math.abs(this._prevDiff - dist) > step) {
					this._options.callback(
						Math.round((this._prevDiff - dist) / step),
						false,
						e1.clientX + (e2.clientX - e1.clientX) / 2,
						e1.clientY + (e2.clientY - e1.clientY) / 2,
						false
					);
					this._prevDiff = dist;
				}
			}

		}
	}
}
