import PageElement from "ui/PageElement";
import template from "./popup-menu.handlebars";
import Template from "ui/Template";

/**
 * @typedef {object} T_PopupMenuOptions
 * @property {HTMLElement} [target]
 * @property {{x:number,y:number}} [position]
 * @property {Array<T_PopupMenuButton>} buttons
 */

/**
 * @typedef {object} T_PopupMenuButton
 * @property {string} caption
 * @property {string} role
 * @property {string} [icon]
 */

export default class PopupMenu extends PageElement {
	/**
	 * @param {T_PopupMenuOptions} options
	 */
	constructor(options) {
		super({
			parent: document.body
		});

		/**
		 * @type {T_PopupMenuOptions}
		 * @private
		 */
		this._options = options;

		/**
		 * @type {function(role:string):void|null}
		 * @private
		 */
		this._callback = null;

		this._element = (new Template(template)).createElement({
			buttons: options.buttons || []
		});

		this._handlers = {
			keyDown: this._onKeyDown.bind(this),
			globalClick: this._onGlobalClick.bind(this),
		};

		this.activatePageElement();
		this._updatePosition();

		this._element.querySelectorAll('.popup-menu__button').forEach((el) => {
			el.addEventListener('click', this._handleButton.bind(this, el.dataset.role));
		});

		setTimeout(() => {
			document.body.addEventListener('contextmenu', this._handlers.globalClick);
			document.body.addEventListener('click', this._handlers.globalClick);
			document.addEventListener('keydown', this._handlers.keyDown);
		}, 50);
	}

	/**
	 * @param {string} role
	 * @returns {Promise<void>}
	 * @protected
	 */
	async _handleButton(role) {
		if (this._callback) {
			this._callback(role);
			this._callback = null;
		}

		this.destroy();
	}

	/**
	 * @return {Promise<string>}
	 */
	async getResult() {
		return new Promise(resolve => {
			this._callback = resolve;
		})
	}

	/**
	 * @param {MouseEvent} e
	 * @private
	 */
	_onGlobalClick(e) {
		if (e.target && e.target.closest('.popup-menu')) {
			return;
		}

		this.destroy();
	}

	/**
	 * @param {KeyboardEvent} e
	 * @private
	 */
	_onKeyDown(e) {
		if (e.code !== 'Escape' || !this._isSelfFocus()) {
			return;
		}

		if (e.target && 'tagName' in e.target && ['TEXTAREA', 'INPUT', 'SELECT'].includes(e.target.tagName)) {
			return;
		}

		this.destroy();
	}

	/**
	 * @private
	 */
	_updatePosition() {
		if (this._options.target) {
			const {left, bottom} = this._options.target.getBoundingClientRect();
			this._element.style.setProperty('--x', `${left}px`);
			this._element.style.setProperty('--y', `${bottom}px`);
		}

		if (this._options.position) {
			const {x, y} = this._options.position;
			this._element.style.setProperty('--x', `${x}px`);
			this._element.style.setProperty('--y', `${y}px`);
		}
	}

	destroy() {
		if (this._callback) {
			this._callback('');
			this._callback = null;
		}
		super.destroy();
	}
}
