import {i18n} from "ui/i18n";
import Ajax from "ui/helpers/Ajax";
import ChangesLoader from "ui/front/changes/ChangesLoader";
import {formatDateOnlyYmd} from "ui/helpers/formatters";

/**
 * @type {T_UpdateOnFrontPage[]|null}
 */
let cachedChanges = null;

/**
 * @type {Promise<boolean>}
 */
let loadPromise = null;

export default class InteractiveChangesLoader {
	constructor() {
		/**
		 * @type {T_UpdateOnFrontPage[]}
		 * @private
		 */
		this._changes = cachedChanges || [];
	}

	/**
	 * @return {T_UpdateOnFrontPage[]}
	 */
	get changes() {
		return this._changes;
	}

	/**
	 * @return {Promise<boolean>}
	 */
	async load() {
		if (cachedChanges !== null) {
			this._changes = cachedChanges;
			return true;
		}

		if (loadPromise) {
			return loadPromise;
		}


		loadPromise = new Promise(async r => {
			try {
				// noinspection JSValidateTypes
				const data = await Ajax.get('addon/changes');
				await this._buildChangesList(data.list);
			} catch (e) {
				r(false);
			}

			r(true);
		})

		await loadPromise;

		this._changes = cachedChanges;

		return true;
	}

	/**
	 * @param {{addon:string,id:number,title:string,author:string,time:number,data:string}[]} addonChanges
	 * @private
	 */
	async _buildChangesList(addonChanges) {
		cachedChanges = [];
		const generalChangesLoader = new ChangesLoader();

		if (!await generalChangesLoader.load()) {
			return;
		}
		/**
		 * @type {Map<string, T_UpdateOnFrontPage|null>}
		 */
		const interactiveGeneralChangeItems = new Map();

		const interactiveIcons = {
			'change-my-mind': 'cup',
			'melody':  'music',
			'fun-drawing':  'play',
		};

		for (const item of addonChanges) {
			if (!interactiveGeneralChangeItems.has(item.addon)) {
				interactiveGeneralChangeItems.set(item.addon, null);

				for (const generalItem of generalChangesLoader.changes) {
					if (generalItem.l.indexOf('interactive://' + item.addon) > -1) {
						interactiveGeneralChangeItems.set(item.addon, generalItem);
						break;
					}
				}
			}

			let generalItem = interactiveGeneralChangeItems.get(item.addon);
			if (!generalItem) {
				continue;
			}

			generalItem = Object.assign({}, generalItem);
			generalItem.l += '||event://select-' + item.addon + '?id='
				+ item.id
				+ '&title=' + encodeURIComponent(item.title)
				+ '&data=' + encodeURIComponent(item.data || '');

			cachedChanges.push({
				id: 0,
				d: formatDateOnlyYmd(item.time * 1000),
				t: item.title !== '' ? item.title : '',
				a: item.author,
				p: generalItem.p,
				m: true,
				l: '',
				icon: interactiveIcons[item.addon] || 'fill',
				ref: generalItem,
			});
		}
	}
}
