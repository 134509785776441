var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"low-battery-dialog\">\n	<div class=\"battery-anim\">\n		<div></div>\n		<div></div>\n	</div>\n	<div class=\"low-battery-dialog__message\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"messages.low-battery",(depth0 != null ? lookupProperty(depth0,"batteryLevel") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":86}}}))
    + "</div>\n</div>\n";
},"useData":true});