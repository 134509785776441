import Template from "ui/Template";
import listView from './html/list.handlebars';
import listBodyView from './html/list-body.handlebars';
import listItemView from './html/list-item.handlebars';
import listCellView from './html/list-cell.handlebars';
import buttonView from './html/list-button.handlebars';
import textButtonView from './html/list-text-button.handlebars';
import linkView from './html/list-link.handlebars';
import columnHeadingView from './html/list-column-heading.handlebars';
import {escapeHtml} from "ui/helpers/formatters";

/**
 * @typedef {T_PageElementOptions} T_ListOptions
 * @property {?boolean} [pickerMode]
 * @property {Array<T_ListColumn>} columns
 * @property {?Array<Object>} [data]
 * @property {?function(data:Object)} [handler]
 * @property {?function(button:Element, data:Object)} [buttonsHandler]
 * @property {?function(): boolean} [nextPageHandler]
 */

/**
 * @typedef {Object} T_ListColumn
 * @property {string} [type]
 * @property {boolean} [hidden]
 * @property {string} [key]
 * @property {string|function(v:string,data:object):string} [className]
 * @property {string|function(v:string,data:object):string} [title]
 * @property {string|function(v:string,data:object):string} [role]
 * @property {string|function(v:string,data:object):string} [icon]
 * @property {function(v:string,data:object):string} [format]
 */

/**
 * @type {object}
 */
let template = null;

/**
 * List Element
 */
export default class List {
	/**
	 * Class constructor
	 *
	 * @param {T_ListOptions} options
	 */
	constructor(options) {
		if (template === null) {
			template = {
				main: new Template(listView),
				body: new Template(listBodyView),
				item: new Template(listItemView),
				cell: new Template(listCellView),
				link: new Template(linkView),
				button: new Template(buttonView),
				textButton: new Template(textButtonView),
				columnHeading: new Template(columnHeadingView),
			}
		}

		/**
		 * @type {T_ListOptions}
		 * @private
		 */
		this._options = options;

		/**
		 * @type {Array<object>}
		 * @private
		 */
		this._sequence = [];

		/**
		 * @type {Map<Object, HTMLElement>}
		 * @private
		 */
		this._itemByObjects = new WeakMap();

		/**
		 * @type {Map<HTMLElement, Object>}
		 * @private
		 */
		this._objectsByItems = new WeakMap();

		this._renderHead();

		/**
		 * @private
		 */
		this._els = {
			preloader: this._el.querySelector('.list-group__preloader'),
			noMsg: this._el.querySelector('.list-group__no-recs'),
			bodies: []
		};

		if (this._options.data) {
			this._renderBody(this._options.data);
		}

		if (options.parent) {
			options.parent.appendChild(this._el);
		}

		if (this._options.nextPageHandler) {
			this._updateNoRecsMsg();
			this._initIntersectionObserver();
		} else {
			this._els.preloader.hidden = true;
			this._updateNoRecsMsg();
		}
	}

	finishList() {
		this._els.preloader.hidden = true;
		this._updateNoRecsMsg();
	}

	reset() {
		for (const body of this._els.bodies) {
			body.remove();
		}

		this._itemByObjects = new WeakMap();
		this._objectsByItems = new WeakMap();
		this._els.bodies = [];
		this._els.noMsg.hidden = false;
		this._els.preloader.hidden = false;
		this._sequence = [];
	}

	/**
	 * Sets list records as list of simple objects
	 *
	 * @param {Array<object>} data
	 * @returns {List}
	 */
	appendData(data) {
		this._sequence.push(...data);
		this._renderBody(data);
		return this;
	}

	deleteRowByObject(data) {
		if (!this._itemByObjects.has(data)) {
			return;
		}

		const item = this._itemByObjects.get(data);
		this._itemByObjects.delete(data);
		this._objectsByItems.delete(item);
		this._sequence.splice(this._sequence.indexOf(data), 1);
		item.remove();
		this._updateNoRecsMsg();
		this._updateSequences();
	}

	updateRow(row) {
		if (!this._itemByObjects.has(row)) {
			return;
		}

		const itemOld = this._itemByObjects.get(row);
		const itemNew = this._createItem(row);

		itemOld.parentElement.replaceChild(itemNew, itemOld);

		this._objectsByItems.delete(itemOld);
		this._objectsByItems.set(itemNew, row);
		this._itemByObjects.set(row, itemNew);
	}

	/**
	 * Returns list element
	 *
	 * @returns {Element}
	 */
	getElement() {
		return this._el;
	}

	/**
	 * @private
	 */
	_updateNoRecsMsg() {
		this._els.noMsg.hidden = !this._els.preloader.hidden
			|| this._el.querySelectorAll('.list-item:not(.list-item_head)').length > 0;
	}

	/**
	 * @private
	 */
	_updateSequences() {

	}

	/**
	 * @private
	 */
	_initIntersectionObserver() {
		const observer = new IntersectionObserver((entries) => {
			if (this._els.preloader.hidden) {
				return;
			}

			for (const entry of entries) {
				if (entry.isIntersecting) {
					this._els.preloader.hidden = !this._options.nextPageHandler();
					break;
				}
			}
		});
		observer.observe(this._els.preloader);
	}

	/**
	 * @param {HTMLElement} item
	 * @param {object} data
	 * @private
	 */
	_attachListenersOnRow(item, data) {
		if (typeof this._options.handler === 'function') {
			item.onclick = () => {
				this._options.handler(data);
			}
		}

		if (typeof this._options.buttonsHandler === 'function') {
			const elsMap = new WeakMap();
			item.querySelectorAll('.button, button, [data-role]').forEach((el) => {
				if (elsMap.has(el)) {
					return;
				}
				elsMap.set(el, 1);
				el.onclick = () => {
					this._options.buttonsHandler(el, data);
				};
			});
		}
	}

	/**
	 * @private
	 */
	_renderHead() {
		if (this._el) {
			return;
		}

		const onHeaderClick = this._onHeaderClick.bind(this);
		this._el = template.main.createElement({
			highlightRecords: this._options.pickerMode
		});

		const cont = this._el.querySelector('.list-item_head');

		for (const column of this._options.columns) {
			if (column.hidden) {
				continue;
			}

			let className =  typeof column.className === 'function' ? column.className('', {}) : column.className;
			let title =  typeof column.title === 'function' ? column.title('', {}) : column.title;

			switch (column.type) {
				case 'button':
				case 'text-button':
				case 'link':
					title = '';
					break;
				case 'sequence':
					title = '#';
					className += ' list-item__cell_sequence';
					break;
			}

			const item = template.columnHeading.createElement({
				className,
				key: column.key,
				type: column.type,
				title,
			});

			cont.appendChild(item);
		}

		if (typeof this._options.headerHandler === 'function') {
			this._el.querySelectorAll('.list-item_head .list-item__cell').forEach((el) => {
				el.addEventListener('click', onHeaderClick);
			});
		}
	}

	/**
	 * @param {Array<Object>} data
	 * @private
	 */
	_renderBody(data) {
		if (data.length === 0) {
			return;
		}

		this._els.noMsg.hidden = true;

		const body = template.body.createElement({});
		for (const row of data) {
			const item = this._createItem(row);
			body.appendChild(item);
			this._objectsByItems.set(item, row);
			this._itemByObjects.set(row, item);
		}

		this._els.bodies.push(body);
		this._el.insertBefore(body, this._els.preloader);
	}

	/**
	 * Handles click by column header
	 *
	 * @param {Event} e
	 * @returns {void}
	 * @private
	 */
	_onHeaderClick(e) {
		const cell = e.target.closest('.list-item__cell');
		const key = cell.getAttribute('data-key');
		let asc = false;

		if (key === '') {
			return;
		}

		// remove sorting from all other columns
		this._el.querySelectorAll('.list-item__cell_sort_asc, .list-item__cell_sort_desc').forEach((el) => {
			if (el !== cell) {
				el.classList.remove('list-item__cell_sort_asc', 'list-item__cell_sort_desc');
			}
		});

		if (cell.classList.contains('list-item__cell_sort_asc')) {
			// change ASC to DESC
			cell.classList.remove('list-item__cell_sort_asc');
			cell.classList.add('list-item__cell_sort_desc');
		} else {
			// change DESC to ASC
			asc = true;
			cell.classList.remove('list-item__cell_sort_desc');
			cell.classList.add('list-item__cell_sort_asc');
		}

		this._options.headerHandler(key, asc);
	}

	/**
	 * @param {object} row
	 * @return {HTMLElement}
	 * @private
	 */
	_createItem(row) {
		const item = template.item.createElement(row);
		for (const column of this._options.columns) {
			if (column.hidden) {
				continue;
			}
			item.appendChild(
				template.cell.createElement(
					this._prepareCell(column, row)
				)
			);
		}

		this._attachListenersOnRow(item, row);

		return item;
	}

	/**
	 * @param {T_ListColumn} column
	 * @param {object} row
	 * @return {object}
	 * @private
	 */
	_prepareCell(column, row) {
		let value = typeof column.key === 'string' ? row[column.key] : '';
		value = column.format ? column.format(value, row) : escapeHtml(value);

		let className =  typeof column.className === 'function' ? column.className(value, row) : column.className;
		let title =  typeof column.title === 'function' ? column.title(value, row) : column.title;
		const role =  typeof column.role === 'function' ? column.role(value, row) : column.role;
		const icon =  typeof column.icon === 'function' ? column.icon(value, row) : column.icon;

		switch (column.type) {
			case 'sequence':
				value = String(this._sequence.indexOf(row) + 1);
				className += ' list-item__cell_sequence';
				title = '';
				break;
			case 'button':
				value = template.button.createHtml({value, role, title, icon});
				title = '';
				break;
			case 'text-button':
				value = template.textButton.createHtml({value, role, title});
				title = '';
				break;
			case 'link':
				value = template.link.createHtml({value, role, title, icon});
				title = '';
				break;
		}

		return {
			className,
			type: column.type || '',
			key: column.key || '',
			title,
			role,
			value,
			icon
		};
	}
}
