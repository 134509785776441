export const WS_MSG_TYPE = {
	CLIENT_STAT: 1
};

export const TARGETS = {
	DIALOG_ABOUT: 1 << 0,
	DIALOG_SUBSCRIBE: 1 << 1,
	CHANGES_BAR: 1 << 2,
	LANG_SWITCH: 1 << 3,
	DIALOG_DOWNLOAD_IMAGES: 1 << 4,
	LINK_EDITOR: 1 << 5,
	LINK_TELEGRAM: 1 << 6,
	LINK_HABR: 1 << 7,
	LINK_YOUTUBE: 1 << 8,
	LINK_PIKABU: 1 << 9,
	LINK_ARTSTATION: 1 << 10,
	LINK_TWITTER: 1 << 11,
	WANDERING: 1 << 12,
	INTERACTIVE_CHANGES: 1 << 13,
};
