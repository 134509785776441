/**
 * Events dispatcher
 */
class EventDispatcher {
	/**
	 * Class constructor
	 */
	constructor() {
		/**
		 * @type {Map<string, Set<function>>}
		 * @private
		 */
		this._eventListeners = new Map();
	}

	/**
	 * Adds event listeners
	 *
	 * @param {string} eventName
	 * @param {Function} callback
	 * @returns {EventDispatcher}
	 */
	addListener(eventName, callback) {
		let map = this._eventListeners,
			set = map.get(eventName);
		if (!set) {
			set = new Set();
			map.set(eventName, set);
		}
		set.add(callback);
		return this;
	}

	/**
	 * Removes event listener
	 *
	 * @param {string} eventName
	 * @param {Function} callback
	 * @return {EventDispatcher}
	 */
	removeListener(eventName, callback) {
		let map = this._eventListeners,
			set = map.get(eventName);
		if (!set) {
			return this;
		}
		set.delete(callback);
		return this;
	}

	/**
	 * Calls event listeners
	 *
	 * @param {string} eventName
	 * @param {*} params
	 * @returns {boolean}
	 */
	trigger(eventName, params = null) {
		let map = this._eventListeners,
			set = map.get(eventName);
		if (!set) {
			return true;
		}
		let flag = true;
		for (let callback of set) {
			flag = callback(params) && flag;
		}
		return flag;
	}
}

export default EventDispatcher;