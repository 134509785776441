import Ajax from "ui/helpers/Ajax";

/**
 * @typedef {object} T_MatrixData
 * @property {number} ver
 * @property {string[]} updates List of update dates
 * @property {{time: number, file: string}} timelapse
 * @property {string} fullsize Prefix for the fullsize frames images
 * @property {time} time Build time
 * @property {{pts:string, url:string}} addons
 * @property {{id:string, preview:string, preview_q:string, video:T_MatrixVideo, video_size:T_MatrixVideoSize}[][]} mat
 * @property {{id:string, r:number, c:number, neighbors:string}[]} perimeter Border scenes (that have at least one adjacent empty block)
 */

/**
 * @typedef {object} T_MatrixVideo
 * @property {string} mp4
 * @property {string[]} f796
 */

/**
 * @typedef {object} T_MatrixVideoSize
 * @property {number} mp4
 * @property {number[]} f796
 */

/**
 * @type {T_MatrixData|null}
 */
let cachedData = null;

/**
 * @type {Promise<boolean>}
 */
let loadPromise = null;

export default class MatrixLoader {
	constructor() {
		/**
		 * @type {T_MatrixData|null}
		 * @private
		 */
		this._data = cachedData;
	}

	/**
	 * @return {T_MatrixData|null}
	 */
	get data() {
		return this._data;
	}

	/**
	 * @return {string[][]}
	 */
	get sceneIds() {
		if (!this._data) {
			return [];
		}

		const out = [];
		const mat = this._data.mat;
		for (let r = 0; r < mat.length; r++) {
			const row = [];
			for (let c = 0; c < mat[r].length; c++) {
				const cell = mat[r][c];
				row.push(cell.id);
			}

			out.push(row);
		}

		return out;
	}

	/**
	 * @return {Promise<boolean>}
	 */
	async load() {
		if (cachedData !== null) {
			this._data = cachedData;
			return true;
		}

		if (loadPromise) {
			return loadPromise;
		}

		loadPromise = new Promise(async r => {
			try {
				// noinspection JSValidateTypes
				cachedData = await Ajax.get('/data/matrix.json?r=' + Math.random());
			} catch (e) {
				r(false);
			}

			r(true);
		})

		await loadPromise;

		this._data = cachedData;

		return true;
	}
}
