
export default class CanvasViewSlotPathClipper {
	/**
	 * @param {string[]} points
	 * @param {MatrixViewPosition} pos
	 */
	constructor(points, pos) {
		/**
		 * @type {MatrixViewPosition}
		 * @private
		 */
		this._pos = pos;

		/**
		 * @type {{x: number, y: number}[]}
		 * @private
		 */
		this._points = points.map(p => this._pos.parsePositionCode(p, false, false)).filter(p => !!p);
	}

	/**
	 * @param {CanvasRenderingContext2D} renderCtx
	 * @param {number} scale
	 */
	clipContext(renderCtx, scale) {
		renderCtx.beginPath();
		let i = 0;
		for (let {x, y} of this._points) {
			x = Math.ceil(((x * this._pos.zoomFactor) - this._pos.x) * scale);
			y = Math.ceil(((y * this._pos.zoomFactor) - this._pos.y) * scale);

			if (i++ === 0) {
				renderCtx.moveTo(x, y);
			} else {
				renderCtx.lineTo(x, y);
			}
		}
		renderCtx.closePath();
		renderCtx.clip();
	}
}
