import Dialog from "ui/elements/dialog/Dialog";
import {i18n} from "ui/i18n";
import tpl from "./download-as-image.handlebars";
import Template from "ui/Template";
import {SCENE_FRAMES, STATIC_URL} from "ui/config";

export default class DownloadAsImageDialog extends Dialog {

	/**
	 * @param {AbstractMatrixView} matrix
	 */
	constructor(matrix) {
		super({
			title: i18n('title.download-as-image'),
			smallWidth: true,
			content: new Template(tpl).createHtml({
				images: (new Array(SCENE_FRAMES)).fill(1).map((v, i) => ({
					index: i + 1,
					url: STATIC_URL + '/data/fullsize/'
						+ matrix.matrixData.fullsize
						+ '_' + String(i).padStart(2, '0') + '.jpg'
				}))
			}),
			buttons: [
				{
					caption: i18n('buttons.close'),
					role: 'close'
				}
			]
		});
	}
}
