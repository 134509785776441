import {getCookie} from "ui/helpers/cookies";
import {i18n} from "ui/i18n";

const AUTH_COOKIE_NAME = '__Secure-796';
const USER_INFO_COOKIE_NAME = '__Secure-user';

export const ROLE_GUEST = 0;
export const ROLE_USER = 1;
export const ROLE_MODERATOR = 2;
export const ROLE_ADMIN = 3;
export const ROLES = Object.fromEntries([
	[ROLE_GUEST, i18n('title.roles.guest')],
	[ROLE_USER, i18n('title.roles.user')],
	[ROLE_MODERATOR, i18n('title.roles.moderator')],
	[ROLE_ADMIN, i18n('title.roles.admin')],
]);

/**
 * @type {CurrentUser}
 */
let self = null;

export class CurrentUser {
	constructor() {
		if (self !== null) {
			return self;
		}

		/**
		 * @type {function()[]}
		 * @private
		 */
		this._observers = [];

		this.hasAccessToken = false;
		this.userName = '';
		this.userId = 0;
		this.userRole = 0;
		this.readCookies();
	}

	static get() {
		if (self === null) {
			self = new CurrentUser();
		}

		return self;
	}

	addObserver(func) {
		this._observers.push(func);
	}

	isAdmin() {
		return this.userRole === ROLE_ADMIN;
	}

	isUser() {
		return this.userRole >= ROLE_USER;
	}

	isModerator() {
		return this.userRole >= ROLE_MODERATOR;
	}

	readCookies() {
		const userIdBefore = this.userId;
		const hasAccessToken = !!getCookie(AUTH_COOKIE_NAME);
		const userInfo = getCookie(USER_INFO_COOKIE_NAME);
		const re = /^(\d+):([a-z0-9]+):([1-3])$/;

		if (!hasAccessToken || !re.test(userInfo)) {
			this.hasAccessToken = false;
			this.userName = '';
			this.userId = 0;
			this.userRole = 0;
			if (this.userId !== userIdBefore) {
				this._observers.forEach(observer => observer());
			}
			return false;
		}

		const match = userInfo.match(re);
		this.hasAccessToken = true;
		this.userName = match[2];
		this.userId = parseInt(match[1]);
		this.userRole = parseInt(match[3]);
		if (this.userId !== userIdBefore) {
			this._observers.forEach(observer => observer());
		}

		return true;
	}
}
