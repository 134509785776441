import spinnerView from './spinner.handlebars';
import Template from "ui/Template";
/**
 * @type {boolean}
 */
let isSpinnerShown = false;

/**
 * @type {Element}
 */
let spinner;

export default class Spinner {
	/**
	 * @return {boolean}
	 */
	static get isShown() {
		return isSpinnerShown;
	}

	static show() {
		isSpinnerShown = true;
		if (!spinner) {
			spinner = new Template(spinnerView).createElement({});
			document.body.appendChild(spinner);
		}

		spinner.classList.add('spinner_show');
	}

	static hide() {
		if (!isSpinnerShown) {
			return;
		}

		isSpinnerShown = false;
		spinner.classList.remove('spinner_show');
	}
}
