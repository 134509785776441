import Ajax from "ui/helpers/Ajax";
import {i18n} from "ui/i18n";
import Dialog from "ui/elements/dialog/Dialog";
import Utils from "ui/helpers/Utils";
import Form from "ui/elements/form/Form";
import Spinner from "ui/components/spinner/Spinner";
import {
	LIMIT_PASSWORD_MIN_LENGTH, LIMIT_USERNAME_MAX_LENGTH,
	LIMIT_USERNAME_MIN_LENGTH,
	validateEmail,
	validatePassword,
	validateUserName
} from "ui/helpers/validators";
import EmailCode from "ui/dialogs/email-code/EmailCode";
import {CurrentUser} from "ui/CurrentUser";

export default class SignupForm extends Dialog {
	constructor() {
		super({
			title: i18n('title.signup'),
			smallWidth: true,
			buttons: [
				{
					caption: i18n('buttons.create-account'),
					role: 'create'
				},
				{
					caption: i18n('buttons.cancel'),
					role: 'close'
				}
			]
		});

		/**
		 * @type {function(res: boolean)}
		 * @private
		 */
		this._onComplete = null;

		/**
		 * @type {Form}
		 * @private
		 */
		this._form = null;

		void this._buildForm();
		this.activatePageElement();

		Spinner.hide();
	}

	async getResult() {
		return new Promise(r => this._onComplete = r);
	}

	/**
	 * Handles click by button
	 *
	 * @override
	 * @param {string} role
	 * @returns {void}
	 * @protected
	 */
	_handleButton(role) {
		super._handleButton(role);
		if (role === 'create') {
			this._submit().then();
		}
	}

	/**
	 * Validates and submit login form
	 *
	 * @private
	 */
	async _submit() {
		let data = this._form.getValues();
		if (data === null) {
			return;
		}

		if (data.password !== data.password2) {
			this._form.showError(i18n('errors.user.passwords-not-match'), ['password', 'password2']);
			return;
		}

		const post = {
			name: data.login,
			email: data.email,
			password: data.password,
			code: '',
			token: '',
		};

		Spinner.show();

		while (true) {
			try {
				const res = await Ajax.post('/user/signup', post);
				Spinner.hide();

				if (res.result === 'error') {
					this._form.showError(res.error, res.fields || []);
					return;
				}

				if (res.result === 'check') {
					post.token = res.token;
					post.code = await this._checkEmailCode(post, res.token);
					if (post.code === '') {
						break;
					}

					continue;
				}

				CurrentUser.get().readCookies();

				if (this._onComplete) {
					this._onComplete(true);
					this._onComplete = null;
				}
				this.destroy();
			} catch (e) {
				this._form.showError(e);
				Spinner.hide();
			}

			break;
		}
	}

	/**
	 * @param {object} post
	 * @param {string} token
	 * @return {Promise<string>}
	 * @private
	 */
	async _checkEmailCode(post, token) {
		return await new EmailCode({
			url: '/user/check-code',
			payload: {...post, token}
		}).getResultPromise();
	}

	/**
	 * Builds form element
	 *
	 * @return {Promise<void>}
	 * @private
	 */
	async _buildForm() {
		let el = document.createElement('div');
		this._form = new Form({
			parent: el,
			onApply: this._submit.bind(this),
			values: {remember: true},
			fields: [
				{
					type: 'text',
					name: 'email',
					caption: i18n('inputs.user.email'),
					hint: i18n('hints.signup.email'),
					maxlength: 100,
					validators: [validateEmail],
					required: true,
				},
				{
					type: 'text',
					name: 'login',
					caption: i18n('inputs.signin.login'),
					hint: i18n('hints.signup.name', LIMIT_USERNAME_MIN_LENGTH, LIMIT_USERNAME_MAX_LENGTH),
					required: true,
					maxlength: LIMIT_USERNAME_MAX_LENGTH,
					validators: [validateUserName]
				},
				{
					type: 'password',
					name: 'password',
					caption: i18n('inputs.signin.password'),
					hint: i18n('hints.signup.password', LIMIT_PASSWORD_MIN_LENGTH),
					required: true,
					maxlength: 256,
					validators: [validatePassword]
				},
				{
					type: 'password',
					name: 'password2',
					caption: i18n('inputs.signin.password-repeat'),
					required: true,
					maxlength: 256,
					validators: [validatePassword]
				},
			]
		});
		this.setContent(el);
		// focus first input
		await Utils.wait(40);
		el.querySelector('input').focus();
	}

	/**
	 * Destroys the login form
	 *
	 * @returns {void}
	 */
	destroy() {
		super.destroy();
		if (this._onComplete) {
			this._onComplete(false);
			this._onComplete = null;
		}
	}
}
