import {easeOutExpo} from "ui/helpers/easing";
import MatrixLoader from "ui/front/matrix/MatrixLoader";
import {SCENE_SLOT_HEIGHT, SCENE_SLOT_WIDTH} from "ui/config";
import EventDispatcher from "ui/EventDispatcher";

const ANIMATION_FPS_QUICK = 1000 / 24;
const ANIMATION_FPS_SLOW = 1000 / 24;
const ANIMATION_DURATION = 12;
const RESUME_RANDOM_MOVE_TIMEOUT = 3000;
const MATRIX_CENTER_SCENE_ID = 't0r0';

export const EVENT_SLOW_MOVE_STARTED = 'slow_move_started';
export const EVENT_SLOW_MOVE_STOPPED = 'slow_move_stopped';

export default class MatrixViewPositionTransition {
	/**
	 * @param {MatrixViewPosition} pos
	 */
	constructor(pos) {
		/**
		 * @type {MatrixViewPosition}
		 * @private
		 */
		this._pos = pos;

		/**
		 * @type {string}
		 * @private
		 */
		this._toCode = '';

		/**
		 * @type {number}
		 * @private
		 */
		this._moveDelta = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._value = 0;

		/**
		 * @type {{x: number, y: number}}
		 * @private
		 */
		this._fromXY = {x: 0, y: 0};

		/**
		 * @type {{x: number, y: number}}
		 * @private
		 */
		this._toXY = {x: 0, y: 0};

		/**
		 * @type {number}
		 * @private
		 */
		this._timerId = 0;

		/**
		 * @type {number}
		 * @private
		 */
		this._resumeTimerId = 0;

		/**
		 * @type {null|function(boolean)}
		 * @private
		 */
		this._resolver = null;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isRandomMove = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isSlowMove = false;

		/**
		 * @type {EventDispatcher}
		 * @protected
		 */
		this._events = new EventDispatcher();

		this._moveObserver = () => {
			this.stop();
		};
		this._pos.addObserver(this._moveObserver);

		window.addEventListener('resize', this.updateProperties.bind(this));
		window.addEventListener('orientationchange', this.updateProperties.bind(this));
	}

	get isRandomMove() {
		return this._isRandomMove;
	}

	/**
	 * @return {EventDispatcher}
	 */
	get events() {
		return this._events;
	}

	stop() {
		if (this._timerId === 0 && this._toCode === '') {
			if (this._isRandomMove) {
				clearTimeout(this._resumeTimerId);
				this._resumeTimerId = setTimeout(this.moveToNextRandomPoint.bind(this), RESUME_RANDOM_MOVE_TIMEOUT);
			}
			return;
		}

		clearTimeout(this._timerId);
		this._timerId = 0;
		this._toCode = '';

		if (this._isSlowMove) {
			this._events.trigger(EVENT_SLOW_MOVE_STOPPED);
		}

		this._isSlowMove = false;

		if (this._resolver) {
			this._resolver(false);
		}

		if (this._isRandomMove) {
			clearTimeout(this._resumeTimerId);
			this._resumeTimerId = setTimeout(this.moveToNextRandomPoint.bind(this), RESUME_RANDOM_MOVE_TIMEOUT);
		}
	}

	updateProperties() {
		if (this._toCode === '') {
			return;
		}

		this._parseCode(this._toCode);
	}

	startRandomMove() {
		this.stop();
		this._isRandomMove = true;
		clearTimeout(this._resumeTimerId);
		this.moveToNextRandomPoint().then();
	}

	stopRandomMove() {
		this.stop();
		this._isRandomMove = false;
	}

	async moveToNextRandomPoint() {
		if (!this._isRandomMove) {
			return false;
		}

		this.stop();
		clearTimeout(this._resumeTimerId);

		let slots = await this._getPerimeterSlots();

		const code = slots[Math.round(Math.random() * slots.length) % slots.length];

		if (!await this.moveSlow(code)) {
			this._events.trigger(EVENT_SLOW_MOVE_STOPPED);
			this.stop();
			return false;
		}

		queueMicrotask(this.moveToNextRandomPoint.bind(this));
	}

	/**
	 * @param {string} code
	 * @return {Promise<boolean>}
	 */
	moveSlow(code) {
		if (this._toCode !== '') {
			this.stop();
		}

		if (!this._parseCode(code)) {
			return Promise.resolve(false);
		}

		this._events.trigger(EVENT_SLOW_MOVE_STARTED);
		this._isSlowMove = true;

		return new Promise(resolve => {
			this._resolver = resolve;

			const update = () => {
				this._value++;

				this._pos.removeObserver(this._moveObserver);
				this._pos.setPosition(
					this._fromXY.x + ((this._toXY.x - this._fromXY.x) / this._moveDelta) * this._value,
					this._fromXY.y + ((this._toXY.y - this._fromXY.y) / this._moveDelta) * this._value
				);
				this._pos.addObserver(this._moveObserver);

				if (this._value >= this._moveDelta) {
					if (this._resolver === resolve) {
						this._resolver = null;
					}
					resolve(true);
					return;
				}

				this._timerId = setTimeout(update, ANIMATION_FPS_SLOW);
			};

			update();
		});
	}

	/**
	 * @param {string} code
	 * @param {number} [duration]
	 * @return {Promise<boolean>}
	 */
	moveQuick(code, duration = 0) {
		if (this._toCode !== '') {
			this.stop();
		}

		if (!this._parseCode(code)) {
			return Promise.resolve(false);
		}

		let time = 0;
		duration = duration > 0 ? duration : Math.max(5, Math.min(ANIMATION_DURATION, Math.ceil(this._moveDelta / 50)));
		let easeFunc = duration < 10 ? x => x : easeOutExpo;

		return new Promise(resolve => {
			this._resolver = resolve;
			const update = () => {
				const c = easeFunc(time / duration);

				this._pos.removeObserver(this._moveObserver);
				this._pos.setPosition(
					this._fromXY.x + (this._toXY.x - this._fromXY.x) * c,
					this._fromXY.y + (this._toXY.y - this._fromXY.y) * c
				);
				this._pos.addObserver(this._moveObserver);

				if (time >= duration) {
					if (this._resolver === resolve) {
						this._resolver = null;
					}
					resolve(true);
					return;
				}

				time++;
				this._timerId = setTimeout(update, ANIMATION_FPS_QUICK);
			};

			update();
		});
	}

	/**
	 * @param {string} code
	 * @return {boolean}
	 * @private
	 */
	_parseCode(code) {
		this._toCode = '';
		const pos = this._pos.parsePositionCode(code);
		if (pos === null) {
			return false;
		}

		const pageWidth = this._pos.width;
		const pageHeight = this._pos.height;

		this._toCode = code;
		this._fromXY.x = this._pos.x;
		this._fromXY.y = this._pos.y;
		this._toXY.x = this._fromXY.x + pos.x - (pageWidth >> 1);
		this._toXY.y = this._fromXY.y + pos.y - (pageHeight >> 1);
		this._moveDelta = Math.max(Math.abs(this._toXY.x - this._fromXY.x), Math.abs(this._toXY.y - this._fromXY.y));
		this._value = 0;

		return true;
	}

	/**
	 * @return {Promise<null|string[]>}
	 * @private
	 */
	async _getPerimeterSlots() {
		const loader = new MatrixLoader();
		await loader.load();

		if (!loader.data) {
			return null;
		}

		const [centerRow, centerCol] = this._getMatrixCenter(loader.data)
		const mat = loader.data.mat;
		const sceneWidth = SCENE_SLOT_WIDTH * 2;
		const sceneHeight = SCENE_SLOT_HEIGHT * 2;

		const pageWidthHalf = (this._pos.width / this._pos.zoomFactor) >> 1;
		const pageHeightHalf = (this._pos.height / this._pos.zoomFactor) >> 1;

		return loader.data.perimeter.map(scene => {
			let c = Math.floor(scene.c + centerCol);
			let r = Math.floor(scene.r + centerRow);

			let x = c * sceneWidth;
			x += SCENE_SLOT_WIDTH + ((scene.c + centerCol) - c > 0 ? SCENE_SLOT_WIDTH : 0);

			let y = r * sceneHeight;
			y += SCENE_SLOT_HEIGHT + ((scene.r + centerRow) - r > 0 ? SCENE_SLOT_HEIGHT : 0);

			const isTopFree = !scene.neighbors.includes('t');
			const isBottomFree = !scene.neighbors.includes('b');
			const isRightFree = !scene.neighbors.includes('r');
			const isLeftFree = !scene.neighbors.includes('l');

			switch (true) {
				case (isRightFree && isBottomFree):
					// align: Bottom-Center
					y += pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
				case (isLeftFree && isBottomFree):
					// align: Middle-Left
					x -= pageWidthHalf - SCENE_SLOT_WIDTH;
					break;
				case (isLeftFree && isTopFree):
					// align: Top-Center
					y -= pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
				case (isRightFree && isTopFree):
					// align: Middle-Right
					x += pageWidthHalf - SCENE_SLOT_WIDTH;
					break;
				case isRightFree:
					// align: Bottom-Right
					x += pageWidthHalf - SCENE_SLOT_WIDTH;
					y += pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
				case isBottomFree:
					// align: Bottom-Left
					x -= pageWidthHalf - SCENE_SLOT_WIDTH;
					y += pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
				case isLeftFree:
					// align: Top-Left
					x -= pageWidthHalf - SCENE_SLOT_WIDTH;
					y -= pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
				case isTopFree:
					// align: Top-Right
					x += pageWidthHalf - SCENE_SLOT_WIDTH;
					y -= pageHeightHalf - SCENE_SLOT_HEIGHT;
					break;
			}

			c = Math.floor(x / sceneWidth);
			r = Math.floor(y / sceneHeight);
			x -= c * sceneWidth;
			y -= r * sceneHeight;

			if (!mat[r][c]) {
				return null;
			}

			return `${mat[r][c].id},${y},${x}`;
		}).filter(x => x !== null);
	}

	/**
	 * @param {T_MatrixData} mat
	 * @private
	 */
	_getMatrixCenter(mat) {
		for (let r = 0; r < mat.mat.length; r++) {
			for (let c = 0; c < mat.mat[r].length; c++) {
				if (mat.mat[r][c].id === MATRIX_CENTER_SCENE_ID) {
					return [r, c];
				}
			}
		}

		return [0, 0];
	}
}